import { language, Locale } from '@getpopsure/i18n-react';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { setRequestErrored, setRequestInProcess } from 'actions/request';
import { RequestAction } from 'constants/actions';
import { APIResponseError } from 'models/error';
import { User, UserNewWithRequiredInfo } from 'models/user';
import { AppState } from 'reducers';
import {
  GenericQuestionnaireAction,
  GenericQuestionnaireState,
} from 'reducers/genericQuestionnaire';
import { ThunkDispatch } from 'redux-thunk';
import endpoint from 'shared/api';
import browserHistory from 'shared/browserHistory';

import { fetchAccountInfo } from '../user';

const history = browserHistory;

export const submitAccountInfo =
  (
    verticalId: keyof GenericQuestionnaireState,
    newAccountRedirectPath: string,
    existingAccountRedirectPath: string,
    newAccount: boolean,
    values?: Partial<User>
  ) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    if (newAccount) {
      history.push(newAccountRedirectPath);
    } else {
      const hasFullName = !!(values?.firstName && values.lastName);

      const userAnswers = {
        email: values?.email,
        ...(hasFullName
          ? {
              name: {
                firstName: values?.firstName || '',
                lastName: values?.lastName || '',
              },
            }
          : {}),
      };

      dispatch(storeGenericQuestionnaireAnswer(verticalId, userAnswers));
      history.push(existingAccountRedirectPath);
    }
  };

export const createAccount =
  (
    verticalId: keyof GenericQuestionnaireState,
    redirectPath: string,
    email: string,
    temporaryLoginCode?: string
  ) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    dispatch(storeGenericQuestionnaireAnswer(verticalId, { email }));
    dispatch(setRequestInProcess(true, 'CREATE_USER'));

    try {
      temporaryLoginCode
        ? await endpoint.signInWithTemporaryLoginCode(temporaryLoginCode, email)
        : await endpoint.createNewUser(email);

      if (temporaryLoginCode) {
        const user = await dispatch(fetchAccountInfo());

        if (!user || !user.email) {
          throw new Error(`[${verticalId}] User info not found`);
        }

        dispatch(
          storeGenericQuestionnaireAnswer(verticalId, {
            name:
              user.firstName && user.lastName
                ? { firstName: user.firstName, lastName: user.lastName }
                : undefined,
          })
        );
      }

      dispatch(setRequestInProcess(false, 'CREATE_USER'));
      history.push(redirectPath);
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, 'CREATE_USER'));
    }
  };

// New authentication related actions ------------------------------------------

export const submitAccount =
  (
    verticalId: keyof GenericQuestionnaireState,
    existingAccountRedirectPath?: string,
    values?: Partial<User>
  ) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    const hasFullName = !!(values?.firstName && values.lastName);
    const userAnswers = {
      email: values?.email,
      ...(hasFullName
        ? {
            name: {
              firstName: values?.firstName || '',
              lastName: values?.lastName || '',
            },
          }
        : {}),
    };

    dispatch(storeGenericQuestionnaireAnswer(verticalId, userAnswers));

    if (existingAccountRedirectPath) {
      history.push(existingAccountRedirectPath);
    }
  };

export const validateAccount =
  (
    verticalId: keyof GenericQuestionnaireState,
    email: string,
    redirectPath: string,
    onUserExists: (userExists: boolean) => void
  ) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    dispatch(setRequestInProcess(true, 'VALIDATE_ACCOUNT'));

    try {
      const {
        data: { userExists },
      } = await endpoint.validateAccount(email);

      dispatch(setRequestInProcess(false, 'VALIDATE_ACCOUNT'));

      onUserExists(userExists);

      if (userExists) {
        await endpoint.sendSignInEmail(email);
        return;
      }

      dispatch(storeGenericQuestionnaireAnswer(verticalId, { email }));

      history.push(redirectPath);
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'VALIDATE_ACCOUNT')
      );
    }
  };

export const requestLoginCode =
  (email: string) =>
  async (
    dispatch: ThunkDispatch<AppState, Record<string, unknown>, RequestAction>
  ) => {
    dispatch(setRequestInProcess(true, 'REQUEST_LOGIN_CODE'));

    try {
      await endpoint.sendSignInEmail(email);
      dispatch(setRequestInProcess(false, 'REQUEST_LOGIN_CODE'));
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'REQUEST_LOGIN_CODE')
      );
    }
  };

export const signinAccount =
  (
    code: string,
    email: string,
    redirectPath: string,
    verticalId: keyof GenericQuestionnaireState
  ) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    dispatch(setRequestInProcess(true, 'SIGN_IN_WITH_LOGIN_CODE'));

    try {
      await endpoint.signInWithTemporaryLoginCode(code, email);

      const user = await dispatch(fetchAccountInfo());

      const hasFullName = !!(user?.firstName && user.lastName);
      const userAnswers = {
        email: user?.email,
        ...(hasFullName
          ? {
              name: {
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
              },
            }
          : {}),
      };

      dispatch(storeGenericQuestionnaireAnswer(verticalId, userAnswers));
      dispatch(setRequestInProcess(false, 'SIGN_IN_WITH_LOGIN_CODE'));
      history.push(redirectPath);
    } catch (error) {
      dispatch(
        setRequestErrored(error as APIResponseError, 'SIGN_IN_WITH_LOGIN_CODE')
      );
    }
  };

export const setAccountDataInQuestionnaire =
  (redirectPath: string, verticalId: keyof GenericQuestionnaireState) =>
  async (
    dispatch: ThunkDispatch<
      AppState,
      Record<string, unknown>,
      | GenericQuestionnaireAction<keyof GenericQuestionnaireState>
      | RequestAction
    >
  ) => {
    dispatch(setRequestInProcess(true, 'SET_ACCOUNT_DATA_IN_QUESTIONNAIRE'));

    try {
      const user = await dispatch(fetchAccountInfo());

      const hasFullName = !!(user?.firstName && user.lastName);
      const userAnswers = {
        email: user?.email,
        ...(hasFullName
          ? {
              name: {
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
              },
            }
          : {}),
      };

      dispatch(storeGenericQuestionnaireAnswer(verticalId, userAnswers));
      dispatch(setRequestInProcess(false, 'SET_ACCOUNT_DATA_IN_QUESTIONNAIRE'));

      if (redirectPath) {
        history.push(redirectPath);
      }
    } catch (error) {
      dispatch(
        setRequestErrored(
          error as APIResponseError,
          'SET_ACCOUNT_DATA_IN_QUESTIONNAIRE'
        )
      );
    }
  };

export const createAccountV2 =
  (accountInfo: UserNewWithRequiredInfo, onSuccess?: () => void) =>
  async (dispatch: ThunkDispatch<AppState, unknown, RequestAction>) => {
    dispatch(setRequestInProcess(true, 'CREATE_USER'));

    try {
      await endpoint.createAccount({
        ...accountInfo,
        language: language() as Locale,
      });

      dispatch(setRequestInProcess(false, 'CREATE_USER'));
      onSuccess?.();
    } catch (error) {
      dispatch(setRequestErrored(error as APIResponseError, 'CREATE_USER'));
    }
  };
