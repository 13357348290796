import { ClaimsAction } from 'constants/actions';

import { HouseholdClaims } from '../models/HouseholdClaims.model';

export const storeHouseholdClaimsAnswers = (
  answer: Partial<HouseholdClaims>
): ClaimsAction => ({
  type: 'STORE_HOUSEHOLD_CLAIM',
  household: answer,
});

export const flushHouseholdClaimsAnswers = (): ClaimsAction => ({
  type: 'FLUSH_HOUSEHOLD_CLAIM',
});
