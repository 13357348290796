import Session from '@getpopsure/session';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from 'components/loadingSpinner';
import dayjs from 'dayjs';
import Cell from 'features/checkout/components/Cell';
import { UserWithBrokerMandate } from 'models/user';
import endpoint from 'shared/api';
import { useSafeTranslation } from 'shared/i18n';
import { capitalizeName } from 'shared/util/capitalizeName';

import { getQuestionnaire } from '../PolicyDetails/PolicyDetails';
import styles from './style.module.scss';
import { getPolicyHolderConfig } from './utils';

const DATE_FORMAT = 'DD MMM YYYY';

const getPolicyHolder = async () => {
  const { data } = await endpoint.getAccountInfo();
  return data;
};

const PolicyHolder = ({ questionnaireId }: { questionnaireId?: string }) => {
  const { isAuthenticated } = Session;
  const { t } = useSafeTranslation();

  const { isFetching: isFetchingUser, data: userData } = useQuery<
    UserWithBrokerMandate | undefined
  >({
    queryKey: ['genericCheckoutAccountInfo'],
    queryFn: () => getPolicyHolder(),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });

  const { isFetching, data: questionnaire } = useQuery<
    Record<string, unknown> | undefined
  >({
    queryKey: ['genericCheckoutQuestionnaire'],
    queryFn: () => getQuestionnaire(questionnaireId),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });

  if (isFetching || isFetchingUser) {
    return <LoadingSpinner />;
  }

  const { firstName, lastName, dateOfBirth, email } = getPolicyHolderConfig(
    userData,
    questionnaire
  );

  if (
    !userData?.firstName &&
    !userData?.lastName &&
    !userData?.email &&
    !userData?.dateOfBirth
  ) {
    return null;
  }

  return (
    <div className={`mb16 ${styles.container}`}>
      <div className="p-h2 mb16">
        {t('components.policyholder.title', 'Policy holder')}
      </div>
      {firstName && lastName && (
        <Cell
          title={t('components.policyholder.name', 'Name')}
          value={capitalizeName({ firstName, lastName })}
        />
      )}
      {dateOfBirth && (
        <Cell
          title={t('components.policyholder.dateofbirth', 'Date of birth')}
          value={dayjs(dateOfBirth).format(DATE_FORMAT)}
        />
      )}
      {email && (
        <Cell
          title={t('components.policyholder.email', 'Email')}
          value={email}
        />
      )}
    </div>
  );
};

export default PolicyHolder;
