import { Region } from '@getpopsure/public-models';
import { PageNotFound } from '@getpopsure/qnr-framework';
import * as Sentry from '@sentry/react';
import LoadingSpinner from 'components/loadingSpinner';
import { QuotePage } from 'components/QuotePage';
import routes from 'constants/routes';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';
import { isStagingOrDev } from 'shared/util/isStagingOrDev';

import { GeneralCheckout } from './components/Checkout/Checkout';
import { RegionManager } from './components/RegionManager';
import { GeneralReview } from './components/Review/Review';
import { SignupQuestionnaire } from './components/SignupQuestionnaire';
import { QuestionnaireJSON } from './model';

const basePath = routes.policies.general.path;
const noRegionPath = routes.policies.general.noRegion.path;
const questionnairePath = routes.policies.general.questionnaire.path;

export const SignupPage = () => {
  const { regionId, verticalId } = useParams<{
    regionId: Region;
    verticalId: string;
  }>();
  const { t } = useSafeTranslation();
  const [json, setJson] = useState<QuestionnaireJSON | false>();

  const getData = useCallback(async () => {
    try {
      const getQuestionnaire = (
        await import(`../../syncedData/questionnaires/${verticalId}.ts`)
      ).default;

      setJson(getQuestionnaire(t));
    } catch (error) {
      Sentry.captureException({
        error,
        tags: {
          feature: 'GENERAL_POLICIES',
        },
        extra: {
          verticalId,
          description: 'Failed to load questionnaire',
        },
      });
      setJson(false);
    }
  }, [t, verticalId]);

  useEffect(() => {
    getData();
  }, [getData, verticalId]);

  if (json === undefined) {
    return <LoadingSpinner />;
  }

  if (!json || (!isStagingOrDev && !json.meta.published)) {
    return <PageNotFound />;
  }

  return (
    <RegionManager
      noRegionPath={noRegionPath}
      questionnairePath={questionnairePath}
      questionnaireMeta={json.meta}
    >
      <SignupQuestionnaire
        basePath={basePath}
        questionnairePath={questionnairePath}
        components={{
          REVIEW: GeneralReview,
          CHECKOUT: GeneralCheckout,
          QUOTE: QuotePage,
        }}
        data={json}
        regionId={regionId}
        verticalId={verticalId}
      />
    </RegionManager>
  );
};
