import { trackPrivateHealthQuoteTableModalOpened } from '@getpopsure/analytics';
import {
  EmploymentStatus,
  getPriceBreakdown,
  tariffNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { ComparisonTable } from '@popsure/dirty-swan';
import { TableModal } from 'features/privateHealthPreSignup/components/TableModal';
import {
  longTermPlanOptions,
  Modal,
  ModalWithTracking,
  shortTermPlanOptions,
  TableData,
  Tariff,
  TariffsWithPublic,
} from 'features/privateHealthPreSignup/models';
import { getPublicHealthContribution } from 'features/privateHealthPreSignup/util';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useCallback, useMemo, useState } from 'react';

import { CashbackModal, ContributionModal } from './components/modals';
import styles from './Coverage.module.scss';
import {
  getLongTermTableData,
  getLongTermTableHeaders,
} from './tableData/longTerm';
import {
  getShortTermTableData,
  getShortTermTableHeaders,
} from './tableData/shortTerm';

export const Coverage = ({
  birthYear,
  income,
  sickDayPayout,
  employmentStatus,
  showShortTermVersion,
  showHeaderPrice = true,
  selectedPlan,
  setSelectedPlan,
}: {
  birthYear: number;
  income: number;
  sickDayPayout: number;
  employmentStatus: EmploymentStatus;
  showShortTermVersion: boolean;
  showHeaderPrice?: boolean;
  selectedPlan?: Tariff;
  setSelectedPlan: (tariff: Tariff) => void;
}) => {
  const isMobile = useMediaQuery('BELOW_MOBILE');

  const [modal, setModal] = useState<null | Modal>();

  const handleOpenModal = useCallback((newModal: ModalWithTracking) => {
    setModal(newModal);
    if (newModal) {
      trackPrivateHealthQuoteTableModalOpened({
        field_key: newModal.fieldKey,
        tariff_name: newModal.tariff,
        is_landing_page: false,
      });
    }
  }, []);

  const planOptions: Tariff[] = showShortTermVersion
    ? shortTermPlanOptions
    : longTermPlanOptions;

  const getTableData = showShortTermVersion
    ? getShortTermTableData
    : getLongTermTableData;
  const getTableHeaders = showShortTermVersion
    ? getShortTermTableHeaders
    : getLongTermTableHeaders;

  const publicHealthContribution = getPublicHealthContribution({
    birthYear,
    income,
    employmentStatus,
  });

  let publicHealthPriceString: string | undefined;

  if (publicHealthContribution) {
    const priceStringPrefix = employmentStatus === 'OTHER' ? '~' : '';
    publicHealthPriceString = `${priceStringPrefix}${englishFormattedEuroCurrency(
      Math.round(publicHealthContribution.total),
      true
    )}`;
  }

  const standardPrice = getPriceBreakdown({
    tariff: showShortTermVersion ? 'HiMedical' : 'NKSelectS',
    birthYear,
    sickDayPayout,
    employmentStatus,
  }).contributions.own;

  const plusPrice = showShortTermVersion
    ? undefined
    : getPriceBreakdown({
        tariff: 'NKSelectL',
        birthYear,
        sickDayPayout,
        employmentStatus,
      }).contributions.own;

  const premiumPrice = getPriceBreakdown({
    tariff: showShortTermVersion ? 'HiMedicalPlus' : 'NKSelectXL',
    birthYear,
    sickDayPayout,
    employmentStatus,
  }).contributions.own;

  const tableData: Array<TableData> = getTableData({
    standardPrice,
    plusPrice,
    premiumPrice,
    publicHealthPriceString,
    sickDayPayout,
    birthYear,
  });

  const tableHeaders = useMemo(() => {
    const handleOpenContributionModal = (tariff: TariffsWithPublic) => {
      handleOpenModal({
        fieldKey: 'contribution',
        tariff,
        title: 'How is my price calculated?',
        children: (
          <ContributionModal
            priceData={
              tariff === 'Public'
                ? publicHealthContribution
                : getPriceBreakdown({
                    birthYear,
                    employmentStatus,
                    sickDayPayout,
                    tariff,
                  })
            }
          />
        ),
      });
    };

    const handleCashbackModal = (tariff: Tariff) =>
      handleOpenModal({
        fieldKey: 'cashback',
        tariff,
        title: `Cashback (${tariffNameMapping[tariff]})`,
        children: (
          <CashbackModal
            birthYear={birthYear}
            income={income || 0}
            tariff={tariff}
          />
        ),
      });

    return getTableHeaders({
      setModal,
      handleOpenContributionModal,
      showPrice: showHeaderPrice,
      setSelectedPlan,
      handleCashbackModal,
      selectedPlan,
    });
  }, [
    sickDayPayout,
    handleOpenModal,
    getTableHeaders,
    employmentStatus,
    income,
    publicHealthContribution,
    birthYear,
    showHeaderPrice,
    selectedPlan,
    setSelectedPlan,
  ]);

  return (
    <div className={`p-container ${styles.container}`}>
      <ComparisonTable
        headers={tableHeaders}
        data={tableData}
        hideDetails
        showDetailsCaption="What’s covered?"
        hideDetailsCaption="Hide details"
        growContent
        collapsibleSections
        hideScrollBarsMobile
        onSelectionChanged={(index) => {
          if (!isMobile) {
            return;
          }
          const maxIndex = planOptions.length - 1;
          return index > maxIndex
            ? setSelectedPlan(planOptions[maxIndex])
            : setSelectedPlan(planOptions[index]);
        }}
      />
      {modal && <TableModal modal={modal} setModal={setModal} />}
    </div>
  );
};
