import { email } from '@getpopsure/private-constants';
import { useSubmitDentalClaim } from 'features/dentalClaims/hooks/useSubmitDentalClaim';
import { DentalClaims } from 'features/dentalClaims/models';
import { getDentalClaims } from 'features/dentalClaims/selectors';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { FormEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSafeTranslation } from 'shared/i18n';
import { PayoutDetails } from 'shared/models/types';
import { isValidIban } from 'shared/util/isValidIban';
import { ZodError } from 'zod';

import { DentalClaimsPayoutDetailsView } from './PayoutDetails.view';

const MIN_AMOUNT = 0.01;

export const DentalClaimsPayoutDetails = ({
  value,
  onSubmitValue,
  metaData: { policyId },
}: CustomComponentProps<DentalClaims>) => {
  const { t } = useSafeTranslation();

  const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>(
    (value as PayoutDetails) ?? { amount: '', iban: '' }
  );
  const [ibanError, setIbanError] = useState<string | null>(null);

  const {
    mutate: submitDentalClaim,
    error,
    isError,
    isLoading,
  } = useSubmitDentalClaim();

  const questionnaire = useSelector(getDentalClaims);

  const getErrorMessage = () => {
    if (!isError) return;

    if (error instanceof ZodError) {
      return t(
        'claims.dental.review.validationError.message',
        'Some information needed to submit your claim are missing or invalid. Please try again or contact us at {{supportEmail}}.',
        { supportEmail: email.help }
      );
    }

    return t(
      'claims.dental.review.error.message',
      'An error occurred while submitting your claim. Please try again or contact us at {{supportEmail}}.',
      { supportEmail: email.help }
    );
  };

  const isSubmissionValid =
    Boolean(payoutDetails.amount) &&
    Number(payoutDetails.amount) >= MIN_AMOUNT &&
    Boolean(payoutDetails.iban) &&
    !isLoading;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setIbanError(null);
    if (!isValidIban(payoutDetails.iban)) {
      setIbanError(
        t(
          'claims.dental.payoutDetails.iban.error',
          'The IBAN you provided is invalid'
        )
      );
      return;
    }

    submitDentalClaim(
      { questionnaire, policyId, payoutDetails },
      {
        onSuccess: () => onSubmitValue(payoutDetails),
      }
    );
  };

  return (
    <DentalClaimsPayoutDetailsView
      handleSubmit={handleSubmit}
      t={t}
      loading={isLoading}
      error={getErrorMessage()}
      payoutDetails={payoutDetails}
      setPayoutDetails={setPayoutDetails}
      validForSubmission={isSubmissionValid}
      minAmount={MIN_AMOUNT}
      ibanError={ibanError}
    />
  );
};
