import { TFunction } from '@getpopsure/i18n-react';
import { insurers } from '@getpopsure/legal-insurance-pricing-engine-v2';
import { website } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { illustrations } from '@popsure/dirty-swan';
import { legal } from 'models/insurances/assets/img';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import { coverageOneYearInTheFutureValidator } from 'SignupQuestionnaire/shared/customValidators';

import { createPostQuote } from '../actions';
import { LegalAddress } from '../components/Address/Address';
import { PolicyDetails } from '../components/policyDetails/PolicyDetails';
import { LegalPostcode } from '../components/Postcode/Postcode';
import Quote from '../components/Quote/Quote';
import {
  employmentStatusMapping,
  genderMapping,
  GroupId,
  Legal,
  numberOfPreviousClaimsMapping,
  reviewLabelMapping,
  terminationPartyMapping,
  yearsSinceLastClaimMapping,
} from '../models';
import { preQuoteQuestions } from './translations';

export const LegalComponents = {
  POSTCODE: LegalPostcode,
  LEGAL_ADDRESS: LegalAddress,
  QUOTE: Quote,
} as const;

export type LifeQuestionnaire = SignupQuestionnaireType<
  Legal,
  GroupId,
  typeof LegalComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  resetAddon: () => void,
  priceWithoutCriminalAddOn: number
): LifeQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    groupId: 'preQuote',
    props: {
      title: t('legal.qnr.preQuote.intro.title', "Hey! Let's get you a quote"),
      subTitle: t('legal.qnr.preQuote.intro.subTitle', 'It takes 1-2 minutes.'),
    },
    screen: {
      continueButtonText: t(
        'legal.qnr.preQuote.intro.continueButtonText',
        'Get started'
      ),
    },
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: preQuoteQuestions(t).dateOfBirth,
    },
    groupId: 'preQuote',
  },
  {
    id: 'under18Blocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'legal.qnr.preQuote.under18Blocker.title',
        "We can't sign you up..."
      ),
      iconType: 'SHIELD',
      description: t(
        'legal.qnr.preQuote.under18Blocker.description',
        'We currently only cover people who are over 18 years old.\n\nYou can speak to one of our insurance experts if you have any questions'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t('legal.qnr.preQuote.under18Blocker.button', 'Speak to us'),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'postcode',
    required: true,
    type: 'POSTCODE',
    props: {},
    screen: {
      question: preQuoteQuestions(t).postcode,
    },
    groupId: 'preQuote',
  },
  {
    id: 'includeSpouse',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: preQuoteQuestions(t).includeSpouse,
      description: t(
        'legal.qnr.preQuote.includeSpouse.description',
        'You can add a spouse or partner for around 10€ per month if they live at the same address as you.'
      ),
      additionalInfo: {
        title: t(
          'legal.qnr.preQuote.includeSpouse.additionalInfo.title',
          'What about children?'
        ),
        description: t(
          'legal.qnr.preQuote.includeSpouse.additionalInfo.description',
          "Children under 18 and any unmarried adult children (who are not fully employed and do not receive social benefits) are generally included, so there is no need to add them separately.\n\nChildren are covered even if they don't live at the same address. However, issues related to their residence at a different address are not covered. For instance, if an adult child living in another city for university has a dispute with their landlord, that specific case would not be covered."
        ),
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'employmentStatus',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: employmentStatusMapping(t),
    },
    screen: {
      question: preQuoteQuestions(t).employmentStatus,
    },
    groupId: 'preQuote',
  },
  {
    id: 'selfEmployedNotice',
    type: 'CUSTOM_BOX',
    props: {},
    screen: {
      question: t(
        'legal.qnr.preQuote.selfEmployedNotice.question',
        'Professional legal disputes involving business owners, full-time self-employment, or freelancing will not be covered'
      ),
      buttonText: t(
        'legal.qnr.preQuote.selfEmployedNotice.buttonText',
        'I understand'
      ),
      skipDetails: { path: website.support, title: 'Talk to us' },
    },
    groupId: 'preQuote',
  },
  {
    id: 'hadGermanLegalInsurance',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: preQuoteQuestions(t).hadGermanLegalInsurance,
    },
    groupId: 'preQuote',
  },
  {
    id: 'insurer',
    type: 'DROPDOWN_SINGLE',
    props: {
      options: insurers.map((insurer) => ({
        label: insurer,
        value: insurer,
      })),
    },
    screen: {
      question: preQuoteQuestions(t).insurer,
    },
    groupId: 'preQuote',
  },

  {
    id: 'numberOfPreviousClaims',
    type: 'RADIO',
    props: {
      mapValue: numberOfPreviousClaimsMapping(t),
    },
    screen: {
      question: preQuoteQuestions(t).numberOfPreviousClaims,
    },
    groupId: 'preQuote',
  },
  {
    id: 'numberOfPreviousClaimsBlocker',
    type: 'BLOCKER',
    props: {
      title: "Unfortunately, we can't sign you up",
      iconType: 'SHIELD',
      description:
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nIf you'd like to understand why or have any questions you can speak to one of our insurance experts, and we'll see what we can do to help you.",
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Speak to us',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'yearsSinceLastClaim',
    type: 'RADIO',
    props: {
      mapValue: yearsSinceLastClaimMapping(t),
    },
    screen: {
      question: preQuoteQuestions(t).yearsSinceLastClaim,
    },
    groupId: 'preQuote',
  },
  {
    id: 'terminationParty',
    type: 'RADIO',
    props: {
      mapValue: terminationPartyMapping(t),
    },
    screen: {
      question: preQuoteQuestions(t).terminationParty,
    },
    groupId: 'preQuote',
  },
  {
    id: 'terminationPartyBlocker',
    type: 'BLOCKER',
    props: {
      title: "Unfortunately, we can't sign you up",
      iconType: 'SHIELD',
      description:
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nIf you'd like to understand why or have any questions you can speak to one of our insurance experts, and we'll see what we can do to help you.",
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: 'Speak to us',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'activeInsuranceBlocker',
    type: 'BLOCKER',
    props: {
      title: "Unfortunately, we can't sign you up",
      iconType: 'SHIELD',
      description:
        "You cannot purchase a new legal insurance policy while your current one is still active.\n\nTo sign up for legal insurance with Feather, you first need to cancel your existing policy and confirm its termination date. Once that's done, you can proceed to purchase a new policy with Feather. Be sure to set the start date of your new Feather policy to match the termination date of your previous one to ensure continuous coverage.",
      buttonProps: [],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'endDateOfPreviousInsurance',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 10 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    screen: {
      question: preQuoteQuestions(t).endDateOfPreviousInsurance,
    },
    groupId: 'preQuote',
  },
  {
    id: 'preQuoteReview',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'SUBMIT_QUESTIONNAIRE',
      reviewValues: [
        {
          title: t('legal.qnr.preQuote.review.dateOfBirth', 'Date of birth'),
          value: { type: 'Date', key: 'dateOfBirth', format: 'DD MMM YYYY' },
          path: 'dateOfBirth',
        },
        {
          title: t('legal.qnr.preQuote.review.postCode', 'Postcode in Germany'),
          value: { type: 'String', key: 'postcode' },
          path: 'postcode',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.includeSpouse',
            'Include spouse or partner?'
          ),
          value: {
            type: 'String',
            key: 'includeSpouse',
            valueMap: {
              true: t('legal.qnr.preQuote.review.yes', 'Yes'),
              false: t('legal.qnr.preQuote.review.no', 'No'),
            },
          },
          path: 'includeSpouse',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.employmentStatus',
            'Employment status'
          ),
          value: {
            type: 'String',
            key: 'employmentStatus',
            valueMap: employmentStatusMapping(t),
          },
          path: 'employmentStatus',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.hadGermanLegalInsurance',
            'Legal insurance in the past 5 years?'
          ),
          value: {
            type: 'String',
            key: 'hadGermanLegalInsurance',
            valueMap: {
              true: t('legal.qnr.preQuote.review.yes', 'Yes'),
              false: t('legal.qnr.preQuote.review.no', 'No'),
            },
          },
          path: 'hadGermanLegalInsurance',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.insurer',
            'Last legal insurance provider'
          ),
          value: {
            type: 'Strings',
            key: 'insurer',
            keys: ['value'],
          },
          path: 'insurer',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.numberOfPreviousClaims',
            'Legal insurance claims in the past 5 years'
          ),
          value: {
            type: 'String',
            key: 'numberOfPreviousClaims',
            valueMap: numberOfPreviousClaimsMapping(t),
          },
          path: 'numberOfPreviousClaims',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.yearsSinceLastClaim',
            'Years since last legal insurance claim'
          ),
          value: {
            type: 'String',
            key: 'yearsSinceLastClaim',
            valueMap: yearsSinceLastClaimMapping(t),
          },
          path: 'yearsSinceLastClaim',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.terminationParty',
            'Previous legal insurance terminated by'
          ),
          value: {
            type: 'String',
            key: 'terminationParty',
            valueMap: terminationPartyMapping(t),
          },
          path: 'terminationParty',
        },
        {
          title: t(
            'legal.qnr.preQuote.review.endDateOfPreviousInsurance',
            'Previous insurance end date'
          ),
          value: {
            type: 'Date',
            key: 'endDateOfPreviousInsurance',
            format: 'DD MMM YYYY',
          },
          path: 'endDateOfPreviousInsurance',
        },
      ],
      verticalId: 'legal',
      insuranceType: 'LEGAL_V2',
      createPostQuote: () => {
        return async () => ({ status: 'SUCCESS' });
      },
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'processing',
    required: true,
    type: 'PROCESSING',
    props: {
      textList: ['Preparing your options'],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    type: 'QUOTE',
    required: true,
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'CUSTOM_INTRO',
    props: {
      title: t(
        'legal.qnr.signup.signupIntro.title',
        "Great! Let's sign you up"
      ),
      subTitle: t(
        'legal.qnr.signup.signupIntro.subTitle',
        'It takes 1-2 minutes.'
      ),
      icon: {
        src: illustrations.shield,
        alt: 'shield',
        width: '85px',
      },
    },
    screen: {
      continueButtonText: t(
        'legal.qnr.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'hasCriminalConvictions',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: preQuoteQuestions(t).hasCriminalConvictions,
    },
    groupId: 'signup',
  },
  {
    id: 'hasCriminalConvictionsBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'legal.qnr.signup.hasCriminalConvictionsBlocker.title',
        "Unfortunately, we can't add criminal protection"
      ),
      description: t(
        'legal.qnr.signup.hasCriminalConvictionsBlocker.description',
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nYou can still proceed without the criminal protection add-on. The __final policy price will be {{newPrice}}__.\n\nIf you have any questions, feel free to speak with one of our insurance experts, and we'll do our best to assist you.",
        {
          newPrice: englishFormattedEuroCurrency(
            priceWithoutCriminalAddOn,
            true
          ),
        }
      ),
      buttonProps: [
        {
          type: 'button',
          caption: t(
            'legal.qnr.signup.hasCriminalConvictionsBlocker.caption',
            'Continue without add-on'
          ),
          onClick: resetAddon,
        },
        {
          type: 'href',
          href: website.support,
          caption: 'Speak to us',
          variant: 'SECONDARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'hasPendingCriminalInvestigation',
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: preQuoteQuestions(t).hasPendingCriminalInvestigation,
    },
    groupId: 'signup',
  },
  {
    id: 'hasPendingCriminalInvestigationBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'legal.qnr.signup.hasPendingCriminalInvestigationBlocker.title',
        "Unfortunately, we can't add criminal protection"
      ),
      description: t(
        'legal.qnr.signup.hasPendingCriminalInvestigationBlocker.description',
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nYou can still proceed without the criminal protection add-on. The __final policy price will be {{newPrice}}__.\n\nIf you have any questions, feel free to speak with one of our insurance experts, and we'll do our best to assist you.",
        {
          newPrice: englishFormattedEuroCurrency(
            priceWithoutCriminalAddOn,
            true
          ),
        }
      ),
      buttonProps: [
        {
          type: 'button',
          caption: t(
            'legal.qnr.signup.criminalProtectionBlocker.caption',
            'Continue without add-on'
          ),
          onClick: resetAddon,
        },
        {
          type: 'href',
          href: website.support,
          caption: 'Speak to us',
          variant: 'SECONDARY',
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'legal',
    },
    screen: {
      layout: 'Standalone',
      question: preQuoteQuestions(t).email,
      description: 'This policy will be accessible in your Feather account.',
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: preQuoteQuestions(t).name,
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: preQuoteQuestions(t).gender,
    },
    groupId: 'signup',
  },
  {
    id: 'address',
    required: true,
    type: 'LEGAL_ADDRESS',
    props: {},
    screen: {
      question: preQuoteQuestions(t).address,
      noMaxWidth: true,
    },
    groupId: 'signup',
  },
  {
    id: 'partnerName',
    type: 'NAME',
    props: {},
    screen: {
      question: preQuoteQuestions(t).partnerName,
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    screen: {
      question: preQuoteQuestions(t).startDate,
    },
    validations: [
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Error',
          value: t(
            'legal.qnr.startDate.validation.errorMessage',
            'Earliest start date is {{value}}. Please select another date.'
          ),
        },
      },
      coverageOneYearInTheFutureValidator(t),
    ],
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'SUBMIT_QUESTIONNAIRE',
      reviewValues: [
        {
          title: reviewLabelMapping(t).email,
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: reviewLabelMapping(t).name,
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: reviewLabelMapping(t).gender,
          value: { type: 'String', key: 'gender', valueMap: genderMapping(t) },
          path: 'gender',
        },
        {
          title: 'Address',
          value: {
            type: 'Strings',
            key: 'address',
            keys: ['street', 'houseNumber', 'postcode', 'city'],
          },
          path: 'address',
        },
        {
          title: reviewLabelMapping(t).partnerName,
          value: {
            type: 'Strings',
            key: 'partnerName',
            keys: ['firstName', 'lastName'],
          },
          path: 'partnerName',
        },
        {
          title: reviewLabelMapping(t).startDate,
          value: { type: 'Date', key: 'startDate', format: 'DD MMM YYYY' },
          path: 'startDate',
        },
      ],
      verticalId: 'legal',
      insuranceType: 'LEGAL_V2',
      createPostQuote: () => createPostQuote(t),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'checkoutInfo',
    required: true,
    type: 'CHECKOUT',
    props: {
      type: 'LEGAL_V2',
      PolicyDetails,
      checkoutIcon: legal,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
