import { Region } from '@getpopsure/public-models';
import {
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { getTrackingObject } from '@getpopsure/tracker';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { mergeCheckoutInfo } from 'features/paymentScreen/paymentScreen.actions';
import {
  linkToStripe,
  startCheckout,
} from 'features/paymentScreen/paymentScreen.api';
import { PaymentScreenThunkDispatch } from 'features/paymentScreen/paymentScreen.thunks';
import lz from 'lz-string';
import { InsuranceTypes, PolicyInfoKind } from 'models/insurances/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import api from 'shared/api';
import { paramsSetUrl } from 'shared/util/paramsSetUrl';

import { PolicyDetailsConfiguration, processPolicyDetailsData } from './utils';

export const GenericCheckout = <Questionnaire extends QuestionnaireQuestions>({
  type,
  questionnaireAnswers,
  region,
  policyInfoKind,
  hasCheckoutDocuments,
  icon,
  changeStartDatePath,
  redirectAddDependent,
  policyDetailsConfiguration,
}: QuestionnaireFormProps<Questionnaire> & {
  type: InsuranceTypes;
  region: Region;
  policyInfoKind: PolicyInfoKind; // mapping on backend based on insurance type and is dependent flag
  hasCheckoutDocuments?: boolean;
  icon?: string;
  changeStartDatePath?: string;
  redirectAddDependent?: keyof Questionnaire | boolean;
  policyDetailsConfiguration?: PolicyDetailsConfiguration;
}) => {
  const history = useHistory();
  const dispatch = useDispatch<PaymentScreenThunkDispatch>();
  const startDate = questionnaireAnswers?.startDate
    ? String(questionnaireAnswers.startDate)
    : undefined;
  const monthlyPriceInEuros = Object(questionnaireAnswers?.quote).price;
  const questionnaireId = questionnaireAnswers?.questionnaireId
    ? String(questionnaireAnswers?.questionnaireId)
    : undefined;
  const quoteId = questionnaireAnswers?.quoteId
    ? String(questionnaireAnswers?.quoteId)
    : undefined;
  // Policy info kind value is need for backend to be able to process the checkout correctly - by applying different logic for each policy info type
  const source = getTrackingObject();

  if (!startDate || !monthlyPriceInEuros || !questionnaireId || !quoteId) {
    throw new Error('Missing start date and monthlyPrice data for checkout');
  }

  const checkoutConfiguration = {
    hasCheckoutDocuments,
    icon,
    changeStartDatePath,
    redirectAddDependent:
      typeof redirectAddDependent === 'string'
        ? questionnaireAnswers[redirectAddDependent]
        : redirectAddDependent,
    policyDetails: policyDetailsConfiguration
      ? processPolicyDetailsData(
          questionnaireAnswers,
          policyDetailsConfiguration
        )
      : undefined,
  };

  const callInitializeCheckout = async () => {
    await linkToStripe(api.network);

    const { data: checkout } = await startCheckout(api.network, {
      startDate,
      insuranceType: type,
      monthlyPriceInEuros,
      policyDetails: {
        type,
      },
      policyInfo: {
        questionnaireId,
        quoteId,
        source,
        regionOfPurchase: region,
      },
      ...(policyInfoKind && { policyInfoKind }),
    });

    if (!checkout) {
      throw new Error('Failed to initialize checkout');
    }

    dispatch(mergeCheckoutInfo(checkout));

    const checkoutUrl = paramsSetUrl(
      generatePath(routes.paymentScreen.pay.path, { checkoutId: checkout.id }),
      [
        {
          key: 'c',
          value: lz.compressToEncodedURIComponent(
            JSON.stringify(checkoutConfiguration)
          ),
        },
      ]
    );

    history.replace(checkoutUrl);
  };

  useEffect(() => {
    callInitializeCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingSpinner />;
};
