import { blog, calendly } from '@getpopsure/private-constants';
import {
  defaultAddonsLookup,
  getDefaultSickDayPayout,
  getPriceBreakdown,
  getTariffLabel,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { paramsSetUrl } from '@getpopsure/public-utility';
import { toast } from '@popsure/dirty-swan';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import { EmailAddressCollectionModal } from 'components/EmailAddressCollectionModal';
import FAQSection from 'components/faq';
import MoreQuestionsSection from 'components/moreQuestions';
import ReviewBadge from 'components/reviewBadge';
import SectionHeader from 'components/sectionHeader';
import routes from 'constants/routes';
import {
  persistPrivatePreSignupQuestionnaire,
  PrivatePreSignupDispatch,
} from 'features/privateHealthPreSignup/actions';
import { TableModal } from 'features/privateHealthPreSignup/components/TableModal';
import {
  longTermPlanOptions,
  Modal,
  PrivatePreSignup,
  shortTermPlanOptions,
  Tariff,
} from 'features/privateHealthPreSignup/models';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { APIResponseError } from 'models/error';
import { CustomComponentProps } from 'models/questionnaireFramework';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatErrorMessageFromError } from 'selectors/requests';
import { useSafeTranslation } from 'shared/i18n';
import { sleep } from 'shared/util/sleep';

import { Coverage } from './Coverage';
import { longTermFAQContent } from './faqContent/longTermFAQ';
import { shortTermFAQContent } from './faqContent/shortTermFAQ';
import { FrozenFooter } from './FrozenFooter/FrozenFooter';
import { Header } from './Header';
import { IncomeProtection } from './IncomeProtection/IncomeProtection';
import { InfoCard } from './InfoCard/InfoCard';
import { PrivateDescriptionModal } from './PrivateDescriptionModal';
import styles from './Quote.module.scss';
import { WhyFeather } from './WhyFeather/WhyFeather';

export const Quote = ({
  questionnaireAnswers,
  onSubmitValue,
}: CustomComponentProps<PrivatePreSignup>) => {
  const { t } = useSafeTranslation();
  const dispatch = useDispatch<PrivatePreSignupDispatch>();

  const {
    birthYear = 1900,
    income = 0,
    employmentStatus = 'OTHER',
    quote,
  } = questionnaireAnswers;

  const defaultSickDayPayout = getDefaultSickDayPayout(income);

  const [sickDayPayout, setSickDayPayout] = useState(
    quote?.sickDayPayout ?? defaultSickDayPayout
  );

  const showLongTermFromURL = useQueryParamValue('showLongTerm') || '';
  const eligibleForShortTermFromURL =
    useQueryParamValue('eligibleForShortTerm') || '';

  useEffect(() => {
    if (
      ['true', 'false'].includes(eligibleForShortTermFromURL) &&
      (eligibleForShortTermFromURL === 'true') !==
        questionnaireAnswers.eligibleForShortTerm
    ) {
      dispatch(
        storeGenericQuestionnaireAnswer('privateHealthPreSignup', {
          eligibleForShortTerm: eligibleForShortTermFromURL === 'true',
        })
      );
    }
  }, [
    eligibleForShortTermFromURL,
    questionnaireAnswers.eligibleForShortTerm,
    dispatch,
  ]);

  const coverageSectionRef = useRef<HTMLDivElement>(null);
  const coverageSectionIntersection = useIntersectionObserver(
    coverageSectionRef,
    { rootMargin: '0px 0px -100%' }
  );

  const [modal, setModal] = useState<null | Modal>();
  const [bookACallModal, setBookACallModal] = useState(false);
  const [sendQuoteModal, setSendQuoteModal] = useState(false);

  const showHeaderPrice = !!coverageSectionIntersection?.isIntersecting;

  const { eligibleForShortTerm } = questionnaireAnswers;

  const showLongTerm = showLongTermFromURL === 'true';

  const showShortTermVersion = !!eligibleForShortTerm && !showLongTerm;

  const planFromRedux = questionnaireAnswers.quote?.tariff;

  const possiblePlanOptions = showShortTermVersion
    ? shortTermPlanOptions
    : longTermPlanOptions;

  const fallbackPlan = possiblePlanOptions[0];

  const [selectedPlan, setSelectedPlan] = useState<Tariff | undefined>(
    planFromRedux && possiblePlanOptions.includes(planFromRedux)
      ? planFromRedux
      : fallbackPlan
  );

  const handleBookACallModal = () => setBookACallModal(true);

  const onOpenShortTermInfoModal = () =>
    setModal({
      title: t(
        'private.qnr.quote.shortTermCard.modal.title',
        'What is the short-term coverage?'
      ),
      children: <PrivateDescriptionModal />,
    });

  const handleSendQuoteModal = () => setSendQuoteModal(true);

  const handleSubmit = () =>
    onSubmitValue({
      tariff: selectedPlan,
      deductible: showShortTermVersion ? '500' : '0',
      selectedAddOns: defaultAddonsLookup[selectedPlan ?? 'NKSelectS'],
      sickDayPayout,
    });

  const priceBreakdown = getPriceBreakdown({
    tariff: selectedPlan ?? 'NKSelectS',
    birthYear,
    sickDayPayout,
    employmentStatus,
  });

  const qnrBasicPrice = getPriceBreakdown({
    tariff: showShortTermVersion ? 'HiMedical' : 'NKSelectS',
    birthYear,
    sickDayPayout,
    employmentStatus,
  }).contributions.own;

  const qnrPremiumPrice = getPriceBreakdown({
    tariff: showShortTermVersion ? 'HiMedicalPlus' : 'NKSelectXL',
    birthYear,
    sickDayPayout,
    employmentStatus,
  }).contributions.own;

  const qnrPlusPrice = showShortTermVersion
    ? undefined
    : getPriceBreakdown({
        tariff: 'NKSelectL',
        birthYear,
        sickDayPayout,
        employmentStatus,
      }).contributions.own;

  return (
    <>
      <EmailAddressCollectionModal
        title={t('private.qnr.quote.sendQuoteModal.title', 'Send me my quote')}
        description={t(
          'private.qnr.quote.sendQuoteModal.description',
          'We will also be able to locate your quote if you reach out for expert advice. No unsolicited emails.'
        )}
        buttonCaption={t(
          'private.qnr.quote.sendQuoteModal.buttonCaption',
          'Send'
        )}
        showMailIcon
        isOpen={sendQuoteModal}
        onClose={() => setSendQuoteModal(false)}
        onSubmit={async ({ setSubmitting, setError, name, email }) => {
          setError('');
          setSubmitting(true);
          const { status, hashOfQuestionnaire, error } = await dispatch(
            persistPrivatePreSignupQuestionnaire({
              answers: questionnaireAnswers,
              basicPrice: qnrBasicPrice,
              plusPrice: qnrPlusPrice,
              premiumPrice: qnrPremiumPrice,
              email,
              name,
              sendEmail: true,
              showLongTerm: showLongTermFromURL === 'true',
            })
          );
          if (status === 'SUCCESS') {
            await sleep(500);
            toast('Your quote has been sent', { type: 'success' });
            dispatch(
              storeGenericQuestionnaireAnswer('privateHealthPreSignup', {
                hashOfQuestionnaire,
              })
            );
            setSubmitting(false);
            setSendQuoteModal(false);
          } else {
            setError(formatErrorMessageFromError(error as APIResponseError));
            setSubmitting(false);
          }
        }}
      />
      <div className="bg-white pb80">
        <Header
          onSendQuoteButtonClick={handleSendQuoteModal}
          onTalkToExpertButtonClick={handleBookACallModal}
        />
        {showLongTerm && !!questionnaireAnswers.eligibleForShortTerm && (
          <InfoCard className="mt24">
            <p className="p-p tc-grey-900 fw-bold">
              You’re also eligible for our{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={`${paramsSetUrl(
                  routes.policies.privateHealthV2.quote.path,
                  [{ key: 'showLongTerm', value: 'false' }]
                )}`}
                className={`p-a c-pointer d-inline-flex ai-center fw-bold ${styles.link}`}
              >
                short-term plans
              </Link>
            </p>
          </InfoCard>
        )}
        {showShortTermVersion && (
          <InfoCard className="mt24">
            <h4 className="p-h4">
              {t(
                'private.qnr.quote.shortTermCard.title',
                'We’re offering you our short-term coverage'
              )}
            </h4>
            <p className="p-p tc-grey-600 mt8">
              Since you’ve been in Germany for less than 4 years, we’re offering
              you a less expensive coverage that’s valid for your first 5 years
              in Germany.{' '}
              <button
                className="ds-interactive-component p-a c-pointer fw-bold"
                onClick={onOpenShortTermInfoModal}
                type="button"
              >
                Learn more
              </button>
            </p>
          </InfoCard>
        )}
        <section className="mt64" ref={coverageSectionRef}>
          <Coverage
            birthYear={birthYear}
            income={income}
            sickDayPayout={sickDayPayout}
            employmentStatus={employmentStatus}
            showShortTermVersion={showShortTermVersion}
            showHeaderPrice={showHeaderPrice}
            selectedPlan={selectedPlan}
            setSelectedPlan={(plan) => setSelectedPlan(plan)}
          />
        </section>
        <section className="p-container mt80">
          <SectionHeader title="Customize" centeredOnMobile />
          <IncomeProtection
            sickDayPayout={sickDayPayout}
            handleSliderChange={(n) => setSickDayPayout(n)}
            income={income}
          />
        </section>
        <section className="p-container mt96">
          <SectionHeader title="Why Feather" />
          <WhyFeather />
        </section>
        <section className="p-body mt96">
          <FAQSection
            markdownClassName={styles.markdown}
            data={
              showShortTermVersion ? shortTermFAQContent : longTermFAQContent
            }
            title="Frequently asked questions"
          />
        </section>
        <section className="p-container d-flex mt96">
          <ReviewBadge className="m-auto" />
        </section>
      </div>
      <EmailAddressCollectionModal
        isOpen={bookACallModal}
        onClose={() => setBookACallModal(false)}
        onSubmit={async ({ setSubmitting, setError, name, email }) => {
          setError('');
          setSubmitting(true);
          const { status, error, questionnaireId } = await dispatch(
            persistPrivatePreSignupQuestionnaire({
              answers: questionnaireAnswers,
              basicPrice: qnrBasicPrice,
              premiumPrice: qnrPremiumPrice,
              email,
              name,
              hashOfLastSubmittedQuestionnaire:
                questionnaireAnswers.hashOfQuestionnaire,
            })
          );
          if (status === 'SUCCESS') {
            setBookACallModal(false);
            document.body.style.overflow = 'auto';
            window.Calendly.initPopupWidget({
              url: calendly.healthInsuranceAdvicePriority,
              prefill: {
                name,
                email,
                customAnswers: {
                  a4: questionnaireId,
                },
              },
            });
          } else {
            setError(formatErrorMessageFromError(error as APIResponseError));
          }
          setSubmitting(false);
        }}
      />
      <MoreQuestionsSection
        bookACallGACategory="PRIVATE_HEALTH_INSURANCE"
        description="Reach out to us for more information or check out our Insurance to better understand private health insurance."
        secondaryAction="Read the Insurance Guide"
        bookACallAction={handleBookACallModal}
        secondaryActionLink={blog.healthInsuranceGuide}
        openInNewTab
      />
      {modal && <TableModal modal={modal} setModal={setModal} />}
      {!!selectedPlan && (
        <FrozenFooter
          caption={`${getTariffLabel(selectedPlan)} plan`}
          price={Math.round(priceBreakdown.contributions.own)}
          onContinueClick={handleSubmit}
        />
      )}
    </>
  );
};
