import { Region } from '@getpopsure/public-models';
import { ErrorWithAction } from 'components/ErrorWithAction';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PreviewCheckout } from './components/Checkout/PreviewCheckout';
import { PreviewQuote } from './components/Quote/PreviewQuote';
import { RegionManager } from './components/RegionManager';
import { PreviewReview } from './components/Review/PreviewReview';
import { SignupQuestionnaire } from './components/SignupQuestionnaire';
import { QuestionnaireJSON } from './model';

const basePath = routes.policies.general.preview.path;
const questionnairePath = routes.policies.general.preview.questionnaire.path;
const noRegionPath = routes.policies.general.preview.noRegion.path;

export const PreviewSignupPage = () => {
  const { regionId, verticalId } = useParams<{
    regionId: Region;
    verticalId: string;
  }>();

  const [json, setJson] = useState<QuestionnaireJSON | false>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const fetchQuestionnaire = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_STRAPI_URL}/${verticalId}?populate=deep`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
          },
        }
      );

      if (!response.ok) {
        const erroredResponse = await response.json();
        throw new Error(
          erroredResponse.error.message ||
            'Failed to fetch questionnaire from Strapi'
        );
      }

      const questionnaire = await response.json();

      if (!questionnaire.meta || !questionnaire.questions) {
        setJson(false);
        throw new Error(
          'Fetched Strapi questionnaire is missing "meta" or "questions" field'
        );
      }

      setJson({
        ...questionnaire,
        removeAnswersLogic: {},
      });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      setJson(false);
    }
  }, [verticalId]);

  useEffect(() => {
    fetchQuestionnaire();
  }, [fetchQuestionnaire, verticalId]);

  if (json === undefined) {
    return <LoadingSpinner />;
  }

  if (errorMessage || !json) {
    return (
      <ErrorWithAction
        title="Questionnaire error"
        description={errorMessage ?? ''}
      />
    );
  }

  return (
    <RegionManager
      noRegionPath={noRegionPath}
      questionnairePath={questionnairePath}
      questionnaireMeta={json.meta}
    >
      <SignupQuestionnaire
        basePath={basePath}
        questionnairePath={questionnairePath}
        data={json}
        components={{
          REVIEW: PreviewReview,
          CHECKOUT: PreviewCheckout,
          QUOTE: PreviewQuote,
        }}
        regionId={regionId}
        verticalId={verticalId}
        isPreview
      />
    </RegionManager>
  );
};
