import { toast } from '@popsure/dirty-swan';
import { useStripe } from '@stripe/react-stripe-js';
import LoadingSpinner from 'components/loadingSpinner';
import routes from 'constants/routes';
import {
  finalizeSetupIntent,
  PaymentMethodSelectorThunkDispatch,
} from 'features/paymentMethodSelector/paymentMethodSelector.thunks';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

/**
 * The customer lands here after confirming a new payment method on
 * a payment provider's page.
 *
 * For example, when setting up PayPal,the customer is taken to
 * the PayPal website to authenticate and allow future payments
 * to be taken by Feather.
 *
 * At the moment, adding a card or a SEPA payment method doesn't use
 * redirects in the flow, so most customers won't end up here at any point.
 *
 * Also, this component is not being rendered when Stripe redirects the customer
 * to the payment authorization flow, even if PayPal is used. In this case
 * the Checkout or PaymentScreen components should process the redirect.
 */
const StripeRedirectGateway = () => {
  const { t } = useSafeTranslation();

  const { checkoutId }: { checkoutId: string } = useParams();
  const clientSecret = useQueryParamValue('setup_intent_client_secret');
  const redirectStatus = useQueryParamValue('redirect_status');
  const makeDefault = useQueryParamValue('makeDefault') === 'true';
  const { localeId }: { localeId: string } = useParams();
  const configurationParam = useQueryParamValue('c');

  const dispatch = useDispatch<PaymentMethodSelectorThunkDispatch>();

  const stripeClient = useStripe();
  const history = useHistory();

  useEffect(() => {
    if (clientSecret && stripeClient) {
      if (redirectStatus === 'succeeded') {
        dispatch(
          finalizeSetupIntent({
            stripeClient,
            clientSecret,
            makeDefault,
          })
        ).then((paymentMethod) => {
          const baseUrl = localeId
            ? generatePath(routes.paymentScreenRegionalised.pay.path, {
                checkoutId,
                localeId,
              })
            : generatePath(routes.paymentScreen.pay.path, {
                checkoutId,
              });

          toast(
            t(
              'paymentScreen.stripeRedirectGateway.successNotification',
              `Your payment information was updated successfully`
            ),
            {
              type: 'information',
              description: t(
                'paymentScreen.stripeRedirectGateway.successNotificationDescription',
                `Please, review and confirm the information to finish your payment.`
              ),
            }
          );

          history.replace(
            `${baseUrl}?paymentMethodId=${paymentMethod.id}&c=${configurationParam}`
          );
        });
      } else {
        toast(
          t(
            'paymentScreen.stripeRedirectGateway.errorNotification',
            'There was an error during the authorization of your payment method'
          ),
          {
            type: 'error',
            description: t(
              'paymentScreen.stripeRedirectGateway.errorNotificationDescription',
              'Please, try adding a new one, or reach out to customer support'
            ),
            duration: 5000,
          }
        );

        const baseUrl = localeId
          ? generatePath(routes.paymentScreenRegionalised.pay.path, {
              checkoutId,
              localeId,
            })
          : generatePath(routes.paymentScreen.pay.path, {
              checkoutId,
            });

        history.replace(generatePath(`${baseUrl}?c=${configurationParam}`));
      }
    }
  }, [
    t,
    clientSecret,
    checkoutId,
    dispatch,
    stripeClient,
    history,
    redirectStatus,
    makeDefault,
    localeId,
    configurationParam,
  ]);

  return <LoadingSpinner />;
};

export default StripeRedirectGateway;
