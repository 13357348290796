import { TFunction } from '@getpopsure/i18n-react';
import {
  AddOn,
  Tariff,
  tariffNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';

import { PolicyCoverageItem } from '../../sections/PolicyCoverage/PolicyCoverage';

type ShortTermTariffs = Extract<Tariff, 'HiMedical' | 'HiMedicalPlus'>;
type ShortTermAddons = Extract<AddOn, 'HD' | 'HDL'>;

export type CoverageKey =
  | `${ShortTermTariffs}`
  | `${ShortTermTariffs}-${ShortTermAddons}`;

const isShortTermTariff = (tariff?: string): tariff is ShortTermTariffs =>
  tariff ? ['HiMedical', 'HiMedicalPlus'].includes(tariff) : false;

/**
 * Returns a plan + tariff combination
 * that is used to get the correct coverage entries
 *
 * @param planName
 * @param addons
 */
const getCoverageKey = (
  planName: ShortTermTariffs,
  addons: AddOn[]
): CoverageKey => {
  const hasDentalAddon = addons?.includes('HD');
  const hasDentalPlusAddon = addons?.includes('HDL');

  if (hasDentalAddon) {
    return `${planName}-HD`;
  }

  if (hasDentalPlusAddon) {
    return `${planName}-HDL`;
  }

  return planName;
};

/**
 * Returns the translated PolicyCoverageItems for short-term tariffs
 */
const getShortTermCoverageEntries: (
  t: TFunction
) => Record<CoverageKey, Array<PolicyCoverageItem>> = (t) => ({
  HiMedical: [
    {
      id: 'DOCTOR_VISITS',
      title: t(
        'myPolicies.policyCoverage.doctorVisits',
        'Doctor visits & prescription drugs'
      ),
      covered: true,
    },
    {
      id: 'PREGNANCY_COVERAGE',
      title: t(
        'myPolicies.policyCoverage.pregnancyCoverage',
        'Pregnancy coverage'
      ),
      description: t(
        'myPolicies.policyCoverage.pregnancyCoverageDescription',
        'excluding non-medical services like doulas, prenatal yoga'
      ),
      covered: true,
    },
    {
      id: 'REMEDY_THERAPY',
      title: t('myPolicies.policyCoverage.remedyTherapy', 'Remedy therapy'),
      description: t(
        'myPolicies.policyCoverage.remedyTherapyDescription',
        'e.g. physiotherapy, occupational therapy, speech therapy'
      ),
      covered: true,
    },
    {
      id: 'MEDICAL_AIDS',
      title: t('myPolicies.policyCoverage.medicalAids', 'Basic medical aids'),
      description: t(
        'myPolicies.policyCoverage.medicalAidsDescription',
        'e.g. insoles, hearing aids, bandages'
      ),
      covered: true,
    },
    {
      id: 'HOSPITAL_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.hospitalTreatments',
        'Hospital treatment'
      ),
      description: t(
        'myPolicies.policyCoverage.hospitalTreatmentsDescription',
        'e.g. in-patient admissions, emergency care'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_CLEANINGS',
      title: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatments',
        'Dental cleanings & treatments'
      ),
      description: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatmentsDescription',
        'e.g. prophylaxis, routine checkups, ceramic fillings'
      ),
      covered: false,
    },
    {
      id: 'PREVENTIVE_EXAMINATIONS_FOR_ADULTS',
      title: t(
        'myPolicies.policyCoverage.preventiveExaminationsForAdults',
        'Preventive examinations for adults'
      ),
      description: t(
        'myPolicies.policyCoverage.preventiveExaminationsDescription',
        'e.g. vaccinations, cancer screenings'
      ),
      covered: false,
    },
    {
      id: 'COSMETIC_PROCEDURES',
      title: t(
        'myPolicies.policyCoverage.cosmeticProcedures',
        'Cosmetic procedures'
      ),
      description: t(
        'myPolicies.policyCoverage.cosmeticProceduresDescription',
        'e.g. teeth whitening, rhinoplasty'
      ),
      covered: false,
    },
  ],
  'HiMedical-HD': [
    {
      id: 'DOCTOR_VISITS',
      title: t(
        'myPolicies.policyCoverage.doctorVisits',
        'Doctor visits & prescription drugs'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.dentalTreatments',
        'Dental treatments'
      ),
      description: t(
        'myPolicies.policyCoverage.dentalTreatmentsDescription',
        'e.g. ceramic fillings, crowns'
      ),
      covered: true,
    },
    {
      id: 'PREGNANCY_COVERAGE',
      title: t(
        'myPolicies.policyCoverage.pregnancyCoverage',
        'Pregnancy coverage'
      ),
      description: t(
        'myPolicies.policyCoverage.pregnancyCoverageDescription',
        'excluding non-medical services like doulas, prenatal yoga'
      ),
      covered: true,
    },
    {
      id: 'REMEDY_THERAPY',
      title: t('myPolicies.policyCoverage.remedyTherapy', 'Remedy therapy'),
      description: t(
        'myPolicies.policyCoverage.remedyTherapyDescription',
        'e.g. physiotherapy, occupational therapy, speech therapy'
      ),
      covered: true,
    },
    {
      id: 'HOSPITAL_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.hospitalTreatments',
        'Hospital treatment'
      ),
      description: t(
        'myPolicies.policyCoverage.hospitalTreatmentsDescription',
        'e.g. in-patient admissions, emergency care'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_CLEANINGS',
      title: t('myPolicies.policyCoverage.dentalCleanings', 'Dental cleanings'),
      description: t(
        'myPolicies.policyCoverage.dentalCleaningsDescription',
        'e.g. prophylaxis, scaling'
      ),
      covered: false,
    },
    {
      id: 'PREVENTIVE_EXAMINATIONS_FOR_ADULTS',
      title: t(
        'myPolicies.policyCoverage.preventiveExaminationsForAdults',
        'Preventive examinations for adults'
      ),
      description: t(
        'myPolicies.policyCoverage.preventiveExaminationsDescription',
        'e.g. vaccinations, cancer screenings'
      ),
      covered: false,
    },
    {
      id: 'COSMETIC_PROCEDURES',
      title: t(
        'myPolicies.policyCoverage.cosmeticProcedures',
        'Cosmetic procedures'
      ),
      description: t(
        'myPolicies.policyCoverage.cosmeticProceduresDescription',
        'e.g. teeth whitening, rhinoplasty'
      ),
      covered: false,
    },
  ],
  'HiMedical-HDL': [
    {
      id: 'DOCTOR_VISITS',
      title: t(
        'myPolicies.policyCoverage.doctorVisits',
        'Doctor visits & prescription drugs'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_CLEANINGS',
      title: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatments',
        'Dental cleanings & treatments'
      ),
      description: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatmentsDescription',
        'e.g. prophylaxis, routine checkups, ceramic fillings'
      ),
      covered: true,
    },
    {
      id: 'PREGNANCY_COVERAGE',
      title: t(
        'myPolicies.policyCoverage.pregnancyCoverage',
        'Pregnancy coverage'
      ),
      description: t(
        'myPolicies.policyCoverage.pregnancyCoverageDescription',
        'excluding non-medical services like doulas, prenatal yoga'
      ),
      covered: true,
    },
    {
      id: 'REMEDY_THERAPY',
      title: t('myPolicies.policyCoverage.remedyTherapy', 'Remedy therapy'),
      description: t(
        'myPolicies.policyCoverage.remedyTherapyDescription',
        'e.g. physiotherapy, occupational therapy, speech therapy'
      ),
      covered: true,
    },
    {
      id: 'PREVENTIVE_EXAMINATIONS_FOR_ADULTS',
      title: t(
        'myPolicies.policyCoverage.preventiveExaminationsForAdults',
        'Preventive examinations for adults'
      ),
      description: t(
        'myPolicies.policyCoverage.preventiveExaminationsDescription',
        'e.g. vaccinations, cancer screenings'
      ),
      covered: false,
    },
    {
      id: 'COSMETIC_PROCEDURES',
      title: t(
        'myPolicies.policyCoverage.cosmeticProcedures',
        'Cosmetic procedures'
      ),
      description: t(
        'myPolicies.policyCoverage.cosmeticProceduresDescription',
        'e.g. teeth whitening, rhinoplasty'
      ),
      covered: false,
    },
    {
      id: 'NON_MEDICAL_PRESCRIPTIONS',
      title: t(
        'myPolicies.policyCoverage.nonMedicalPrescriptions',
        'Prescriptions that are not classified as medication'
      ),
      description: t(
        'myPolicies.policyCoverage.nonMedicalPrescriptionsDescription',
        'e.g. contraceptives, supplements, skin care, hair growth products'
      ),
      covered: false,
    },
  ],
  HiMedicalPlus: [
    {
      id: 'DOCTOR_VISITS',
      title: t(
        'myPolicies.policyCoverage.doctorVisits',
        'Doctor visits & prescription drugs'
      ),
      covered: true,
    },
    {
      id: 'VISUAL_MEDICAL_AIDS',
      title: t(
        'myPolicies.policyCoverage.visualMedicalAids',
        'Visual & medical aids'
      ),
      covered: true,
    },
    {
      id: 'PREGNANCY_COVERAGE',
      title: t(
        'myPolicies.policyCoverage.pregnancyCoverage',
        'Pregnancy coverage'
      ),
      description: t(
        'myPolicies.policyCoverage.pregnancyCoverageDescription',
        'excluding non-medical services like doulas, prenatal yoga'
      ),
      covered: true,
    },
    {
      id: 'PREVENTIVE_EXAMINATIONS_FOR_ADULTS',
      title: t(
        'myPolicies.policyCoverage.preventiveExaminations',
        'Preventive examinations'
      ),
      description: t(
        'myPolicies.policyCoverage.preventiveExaminationsDescription',
        'e.g. vaccinations, cancer screenings'
      ),
      covered: true,
    },
    {
      id: 'REMEDY_THERAPY',
      title: t('myPolicies.policyCoverage.remedyTherapy', 'Remedy therapy'),
      covered: true,
    },
    {
      id: 'HOSPITAL_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.hospitalTreatments',
        'Hospital treatment'
      ),
      description: t(
        'myPolicies.policyCoverage.hospitalTreatmentsDescription',
        'e.g. in-patient admissions, emergency care'
      ),
      covered: true,
    },
    {
      id: 'COSMETIC_PROCEDURES',
      title: t(
        'myPolicies.policyCoverage.cosmeticProcedures',
        'Cosmetic procedures'
      ),
      description: t(
        'myPolicies.policyCoverage.cosmeticProceduresDescription',
        'e.g. teeth whitening, rhinoplasty'
      ),
      covered: false,
    },
    {
      id: 'DENTAL_CLEANINGS',
      title: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatments',
        'Dental cleanings & treatments'
      ),
      description: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatmentsDescription',
        'e.g. prophylaxis, routine checkups, ceramic fillings'
      ),
      covered: false,
    },
  ],
  'HiMedicalPlus-HD': [
    {
      id: 'DOCTOR_VISITS',
      title: t(
        'myPolicies.policyCoverage.doctorVisits',
        'Doctor visits & prescription drugs'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.dentalTreatments',
        'Dental treatments'
      ),
      description: t(
        'myPolicies.policyCoverage.dentalTreatmentsDescription',
        'e.g. ceramic fillings, crowns'
      ),
      covered: true,
    },
    {
      id: 'PREGNANCY_COVERAGE',
      title: t(
        'myPolicies.policyCoverage.pregnancyCoverage',
        'Pregnancy coverage'
      ),
      description: t(
        'myPolicies.policyCoverage.pregnancyCoverageDescription',
        'excluding non-medical services like doulas, prenatal yoga'
      ),
      covered: true,
    },
    {
      id: 'PREVENTIVE_EXAMINATIONS',
      title: t(
        'myPolicies.policyCoverage.preventiveExaminations',
        'Preventive examinations'
      ),
      description: t(
        'myPolicies.policyCoverage.preventiveExaminationsDescription',
        'e.g. vaccinations, cancer screenings'
      ),
      covered: true,
    },
    {
      id: 'REMEDY_THERAPY',
      title: t('myPolicies.policyCoverage.remedyTherapy', 'Remedy therapy'),
      description: t(
        'myPolicies.policyCoverage.remedyTherapyDescription',
        'e.g. physiotherapy, occupational therapy, speech therapy'
      ),
      covered: true,
    },
    {
      id: 'HOSPITAL_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.hospitalTreatments',
        'Hospital treatment'
      ),
      description: t(
        'myPolicies.policyCoverage.hospitalTreatmentsDescription',
        'e.g. in-patient admissions, emergency care'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_CLEANINGS',
      title: t('myPolicies.policyCoverage.dentalCleanings', 'Dental cleanings'),
      description: t(
        'myPolicies.policyCoverage.dentalCleaningsDescription',
        'e.g. prophylaxis, scaling'
      ),
      covered: false,
    },
    {
      id: 'COSMETIC_PROCEDURES',
      title: t(
        'myPolicies.policyCoverage.cosmeticProcedures',
        'Cosmetic procedures'
      ),
      description: t(
        'myPolicies.policyCoverage.cosmeticProceduresDescription',
        'e.g. teeth whitening, rhinoplasty'
      ),
      covered: false,
    },
  ],
  'HiMedicalPlus-HDL': [
    {
      id: 'DOCTOR_VISITS',
      title: t(
        'myPolicies.policyCoverage.doctorVisits',
        'Doctor visits & prescription drugs'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_CLEANINGS',
      title: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatments',
        'Dental cleanings & treatments'
      ),
      description: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatmentsDescription',
        'e.g. prophylaxis, routine checkups, ceramic fillings'
      ),
      covered: true,
    },
    {
      id: 'PREVENTATIVE_CARE_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.preventiveExaminations',
        'Preventive examinations'
      ),
      description: t(
        'myPolicies.policyCoverage.preventativeCareTreatmentsDescription',
        'e.g. vaccinations, cancer screenings'
      ),
      covered: true,
    },
    {
      id: 'PREGNANCY_COVERAGE',
      title: t(
        'myPolicies.policyCoverage.pregnancyCoverage',
        'Pregnancy coverage'
      ),
      description: t(
        'myPolicies.policyCoverage.pregnancyCoverageDescription',
        'excluding non-medical services like doulas, prenatal yoga'
      ),
      covered: true,
    },
    {
      id: 'VISUAL_MEDICAL_AIDS',
      title: t(
        'myPolicies.policyCoverage.visualMedicalAids',
        'Visual & medical aids'
      ),
      covered: true,
    },
    {
      id: 'COSMETIC_PROCEDURES',
      title: t(
        'myPolicies.policyCoverage.cosmeticProcedures',
        'Cosmetic procedures'
      ),
      description: t(
        'myPolicies.policyCoverage.cosmeticProceduresDescription',
        'e.g. teeth whitening, rhinoplasty'
      ),
      covered: false,
    },
    {
      id: 'NON_MEDICAL_PRESCRIPTIONS',
      title: t(
        'myPolicies.policyCoverage.nonMedicalPrescriptions',
        'Prescriptions that are not classified as medication'
      ),
      description: t(
        'myPolicies.policyCoverage.nonMedicalPrescriptionsDescription',
        'e.g. contraceptives, supplements, skin care, hair growth products'
      ),
      covered: false,
    },
  ],
});

/**
 * Returns the translated PolicyCoverageItems for emergency tariffs (NLTN.)
 */
const getEmergencyCoverageEntries = (t: TFunction) => [
  {
    id: 'ACUTE_ILLNESSES',
    title: t(
      'myPolicies.policyCoverage.acuteIllnesses',
      'Treatment for acute illnesses'
    ),
    covered: true,
  },
  {
    id: 'PAINFUL_CONDITIONS',
    title: t(
      'myPolicies.policyCoverage.painfulConditions',
      'Treatment for painful conditions'
    ),
    covered: true,
  },
  {
    id: 'PREGNANCY_CARE',
    title: t('myPolicies.policyCoverage.pregnancyCare', 'Pregnancy care'),
    covered: true,
  },
  {
    id: 'TREATMENT_FOR_CHILDREN',
    title: t(
      'myPolicies.policyCoverage.childrenAndAdolescents',
      'Illness or injury treatment for children and adolescents'
    ),
    covered: true,
  },
  {
    id: 'DENTAL_FILLINGS',
    title: t(
      'myPolicies.policyCoverage.dentalFillings',
      'Necessary dental fillings & pain relief'
    ),
    covered: true,
  },
  {
    id: 'PREVENTIVE_EXAMS',
    title: t(
      'myPolicies.policyCoverage.preventiveExaminationsForAdults',
      'Preventive examinations for adults'
    ),
    covered: false,
  },
  {
    id: 'VISUAL_MEDICAL_AIDS',
    title: t(
      'myPolicies.policyCoverage.visualMedicalAids',
      'Visual & medical aids'
    ),
    covered: false,
  },
  {
    id: 'NON_MEDICATION_PRESCRIPTIONS',
    title: t(
      'myPolicies.policyCoverage.nonMedicationPrescriptions',
      'Non-medication prescriptions'
    ),
    covered: false,
  },
];

export const getCoverageEntries = (
  t: TFunction,
  planName?: string,
  addons: AddOn[] = []
) => {
  if (!planName) return [];

  if (isShortTermTariff(planName)) {
    return (
      getShortTermCoverageEntries(t)[getCoverageKey(planName, addons)] || []
    );
  }

  if (planName === 'NLTN.') {
    return getEmergencyCoverageEntries(t);
  }
  /**
   * If the policy is not a short-term tariff use old entries
   */
  const planShortName = tariffNameMapping[planName as Tariff];
  // All plans except Standard are considered as premium for the table
  // this includes Plus and Standard Plus
  const isPlanStandard = planShortName === 'Standard';

  return [
    {
      id: 'DOCTOR_VISITS',
      title: t(
        'myPolicies.policyCoverage.doctorVisits',
        'Doctor visits & prescription drugs'
      ),
      covered: true,
    },
    {
      id: 'DENTAL_CLEANINGS',
      title: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatments',
        'Dental cleanings & treatments'
      ),
      description: t(
        'myPolicies.policyCoverage.dentalCleaningsAndTreatmentsDescription',
        'e.g. prophylaxis, routine checkups, ceramic fillings'
      ),
      covered: true,
    },
    ...(isPlanStandard
      ? [
          {
            id: 'VISUAL_MEDICAL_AIDS',
            title: t(
              'myPolicies.policyCoverage.visualMedicalAids',
              'Visual & medical aids'
            ),
            covered: true,
          },
        ]
      : []),
    {
      id: 'PREVENTATIVE_CARE_TREATMENTS',
      title: t(
        'myPolicies.policyCoverage.preventativeExaminations',
        'Preventive examinations'
      ),
      description: t(
        'myPolicies.policyCoverage.preventativeCareTreatmentsDescription',
        'e.g. vaccinations, cancer screenings'
      ),
      covered: true,
    },
    ...(isPlanStandard
      ? []
      : [
          {
            id: 'VISUAL_MEDICAL_AIDS',
            title: t(
              'myPolicies.policyCoverage.visualMedicalAids',
              'Visual & medical aids'
            ),
            covered: true,
          },
        ]),
    {
      id: 'PREGNANCY_COVERAGE',
      title: t(
        'myPolicies.policyCoverage.pregnancyCoverage',
        'Pregnancy coverage'
      ),
      description: t(
        'myPolicies.policyCoverage.pregnancyCoverageDescription',
        'excluding non-medical services like doulas, prenatal yoga'
      ),
      covered: true,
    },
    {
      id: 'COSMETIC_PROCEDURES',
      title: t(
        'myPolicies.policyCoverage.cosmeticProcedures',
        'Cosmetic procedures'
      ),
      description: t(
        'myPolicies.policyCoverage.cosmeticProceduresDescription',
        'e.g. teeth whitening, rhinoplasty'
      ),
      covered: false,
    },
    {
      id: 'NON_MEDICAL_PRESCRIPTIONS',
      title: t(
        'myPolicies.policyCoverage.nonMedicalPrescriptions',
        'Prescriptions that are not classified as medication'
      ),
      description: t(
        'myPolicies.policyCoverage.nonMedicalPrescriptionsDescription',
        'e.g. contraceptives, supplements, skin care, hair growth products'
      ),
      covered: false,
    },
  ];
};
