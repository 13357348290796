import { Card, Checkbox, SegmentedControl } from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import SectionHeader from 'components/sectionHeader';
import { SliderWithModal } from 'components/SliderWithModal';
import { Dispatch, SetStateAction } from 'react';

import { QuoteCustomization, QuoteOptions } from '../../Quote.models';
import styles from './OptionCustomization.module.scss';

export const OptionCustomization = ({
  quoteOptions,
  selectedQuoteOptions,
  setQuoteCustomization,
}: {
  quoteOptions: QuoteOptions[];
  setQuoteCustomization: Dispatch<SetStateAction<QuoteCustomization>>;
  selectedQuoteOptions?: Record<string, unknown>;
}) => {
  return (
    <>
      {quoteOptions.map(
        ({
          __component,
          title: customizationTitle,
          customizationOptions,
          description,
          optionKey,
          sliderLabel = '',
          sliderSubLabel = '',
          minimumValue = 0,
          maximumValue = 0,
          type = '',
        }) => {
          return (
            <section key={optionKey} className="mt56 mb80">
              <SectionHeader centeredOnMobile title={customizationTitle} />

              <Card
                dropShadow={false}
                classNames={{
                  wrapper: classNames('mt24', styles.card),
                }}
              >
                <Markdown>{description}</Markdown>

                {__component === 'quote-page.quote-customization-slider' && (
                  <SliderWithModal
                    className="my24"
                    label={sliderLabel}
                    sublabel={sliderSubLabel}
                    max={maximumValue}
                    min={minimumValue}
                    setValue={(value) =>
                      setQuoteCustomization((prevState) => ({
                        ...prevState,
                        selectedQuoteOptions: {
                          ...prevState.selectedQuoteOptions,
                          [optionKey]: value,
                        },
                      }))
                    }
                    value={Number(selectedQuoteOptions?.[optionKey] || 0)}
                  />
                )}

                {type === 'switch' && customizationOptions && (
                  <SegmentedControl
                    className="my24"
                    values={customizationOptions.map(({ label }) => label)}
                    onChange={(index) =>
                      setQuoteCustomization((prevState) => ({
                        ...prevState,
                        selectedQuoteOptions: {
                          ...prevState.selectedQuoteOptions,
                          [optionKey]: customizationOptions?.[index].value,
                        },
                      }))
                    }
                    selectedIndex={
                      selectedQuoteOptions?.[optionKey]
                        ? customizationOptions.findIndex(
                            ({ value }) =>
                              value === selectedQuoteOptions?.[optionKey] || ''
                          )
                        : 0
                    }
                  />
                )}

                {type === 'checkbox' && customizationOptions && (
                  <Checkbox
                    classNames={{ container: 'my24' }}
                    wide
                    inlineLayout
                    value={
                      (selectedQuoteOptions?.[optionKey] as string[]) || []
                    }
                    onChange={(value) =>
                      setQuoteCustomization((prevState) => ({
                        ...prevState,
                        selectedQuoteOptions: {
                          ...prevState.selectedQuoteOptions,
                          [optionKey]: value,
                        },
                      }))
                    }
                    options={customizationOptions.reduce(
                      (acc: Record<string, string>, { label, value }) => {
                        acc[value] = label;
                        return acc;
                      },
                      {}
                    )}
                  />
                )}
              </Card>
            </section>
          );
        }
      )}
    </>
  );
};
