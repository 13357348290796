import { QuoteRequest, QuoteResponse } from 'components/QuotePage/Quote.models';
import api from 'shared/api';

export const getQuotes = async (payload: QuoteRequest) => {
  const response = await api.network.post<Array<QuoteResponse>>(
    '/signups/quotes',
    payload
  );

  return response.data;
};
