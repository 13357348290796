import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { ClaimSubmitInfo } from 'models/claims';
import endpoint from 'shared/api';

import { ExpatClaim, ZSubmitClaimInfo } from '../models';

export const useSubmitExpatClaim = (
  currentAnswer: Partial<ExpatClaim>,
  currentPolicyId: string
) => {
  const onSubmitClaim = ({
    answers,
    policyId,
  }: {
    answers: Partial<ExpatClaim>;
    policyId: string;
  }) => {
    if (!policyId) {
      throw new Error(
        '[Expat Submit Claims] Policy ID is missing for submitting claim.'
      );
    }

    const validationResult = ZSubmitClaimInfo.parse({
      ...answers,
    });

    const validatedData = validationResult;
    const isCostPlanClaim = validatedData.claimType === 'COST_PLAN';

    const claimInfo: ClaimSubmitInfo = {
      userPolicyId: policyId,
      claimTypes: [
        {
          claimType: validatedData.claimType,
          additionalInfo: validatedData.additionalInfo ?? undefined,
        },
      ],
      uploadTokens: validatedData.uploadDocuments.map((file) => file.token),
      amount: isCostPlanClaim ? undefined : validatedData.payoutDetails.amount,
      iban: isCostPlanClaim ? undefined : validatedData.payoutDetails.iban,
    };

    return endpoint.submitClaim(claimInfo);
  };

  const { mutateAsync } = useMutation({
    mutationFn: onSubmitClaim,
    onError: (error, variables) => {
      const { policyId, answers } = variables;
      Sentry.captureException(error, {
        tags: {
          feature: 'CLAIMS',
          vertical: 'EXPAT',
        },
        extra: {
          policyId,
          questionnaire: answers,
          description: 'Error happened while submitting Expat claims',
        },
      });
    },
  });

  const submitClaimRequest = () =>
    mutateAsync({
      answers: currentAnswer,
      policyId: currentPolicyId,
    });

  return { submitClaimRequest };
};
