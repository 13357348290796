import { capitalizeName } from '@getpopsure/public-utility';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import { NavButton } from 'components/NavButton';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import {
  getClaimRouteForInsuranceType,
  insuranceWithClaimRoute,
} from 'features/claimsV2/utils';
import { InsuranceTypes } from 'models/insurances/types';
import { getTitleMapping } from 'models/insurances/types/mapping';
import { Policy } from 'models/policies';
import { Name } from 'models/user';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { getAccountInfo } from 'selectors/user';
import { TFunction } from 'shared/i18n';

import styles from './CreateClaim.module.scss';

const DATE_FORMAT = 'DD MMM YYYY';

type CreateClaimViewProps = {
  policies: Policy[];
  t: TFunction;
};

export const CreateClaimView = ({ policies, t }: CreateClaimViewProps) => {
  const [selectedPolicy, setSelectedPolicy] = useState<Policy | null>(null);
  const [showExpired, setShowExpired] = useState(false);
  const account = useSelector(getAccountInfo);
  const isCommonClaims = useFlag('app_common_claims');

  const history = useHistory();

  const applicablePolicies = policies
    .filter((policy) => {
      const filteredInsuranceRoutes = !isCommonClaims
        ? insuranceWithClaimRoute.filter((route) => route !== 'PET_HEALTH')
        : insuranceWithClaimRoute;

      if (!filteredInsuranceRoutes.includes(policy.type)) {
        return false;
      }
      // filter out not started policies
      return policy.attributes.startDate
        ? !dayjs().isBefore(policy.attributes.startDate)
        : true;
    })
    .sort((a, b) => {
      const titleA = getTitleMapping(t)[a.type];
      const titleB = getTitleMapping(t)[b.type];
      if (titleA === titleB) {
        return a.id <= b.id ? -1 : 1;
      }
      return titleA <= titleB ? -1 : 1;
    });

  const onSubmit = () => {
    if (!selectedPolicy) {
      return;
    }
    const path = getClaimRouteForInsuranceType(selectedPolicy.type);

    if (path === undefined) {
      return;
    }

    history.push(
      generatePath(path, {
        policyId: selectedPolicy.id,
      })
    );
  };

  const isPolicyActive = (policy: Policy) => {
    if (policy.status === 'DROPPED_OUT') {
      return false;
    }
    return policy.attributes.activeUntil
      ? dayjs().isBefore(policy.attributes.activeUntil)
      : true;
  };

  const duplicateInsuranceTypeLookup = useMemo(
    () =>
      applicablePolicies.reduce((acc, policy) => {
        if (acc[policy.type]) {
          acc[policy.type] += 1;
        } else {
          acc[policy.type] = 1;
        }
        return acc;
      }, {} as Record<InsuranceTypes, number>),
    [applicablePolicies]
  );

  const isDuplicateInsuranceType = (type: InsuranceTypes) => {
    return duplicateInsuranceTypeLookup[type]
      ? duplicateInsuranceTypeLookup[type] > 1
      : false;
  };

  const isPolicyHolderMatch = (insuredPerson: Name) => {
    return (
      account?.firstName === insuredPerson?.firstName &&
      account?.lastName === insuredPerson?.lastName
    );
  };

  const hideExpiredPolicies = () => {
    setShowExpired(false);
    if (selectedPolicy && !isPolicyActive(selectedPolicy)) {
      setSelectedPolicy(null);
    }
  };

  const getSubTitle = (policy: Policy) => {
    if (policy.type === 'BIKE') {
      return `${policy.attributes?.model} ${policy.attributes?.brand}`.trim();
    }

    if (policy.type === 'HOUSEHOLD') {
      const { street, houseNumber } = policy.attributes?.householdAttributes
        ?.personalInfo?.address ?? { street: '', houseNumber: '' };
      return `${street} ${houseNumber}`.trim();
    }
    const policyHolder = policy.attributes.insuredPerson?.name ?? null;
    if (!policyHolder) {
      return '';
    }
    if (
      isDuplicateInsuranceType(policy.type) ||
      !isPolicyHolderMatch(policyHolder)
    ) {
      return capitalizeName(policyHolder);
    }
    return '';
  };

  const hasExpiredPolicies =
    applicablePolicies.filter((policy) => !isPolicyActive(policy)).length > 0;

  return (
    <div className="p-body">
      <div
        className={classNames(
          'd-flex fd-column wmx12 ml-auto mr-auto',
          styles.container
        )}
      >
        <NavButton
          title={t('claims.create.navButton.title', 'Claims')}
          path={routes.claims.path}
        />
        <div className={styles.contentContainer}>
          <div className="p-h1">
            {t('claims.create.title', 'Which policy is this claim for?')}
          </div>
          <div className="p-label-container mt16">
            {applicablePolicies.filter(isPolicyActive).map((policy) => {
              return (
                <div className="mt8 wmx6" key={policy.id}>
                  <input
                    id={policy.id}
                    className="p-radio mr8"
                    type="radio"
                    onChange={() => setSelectedPolicy(policy)}
                    checked={policy.id === selectedPolicy?.id}
                  />
                  <label
                    htmlFor={policy.id}
                    className="p-label p-label--bordered pr16"
                  >
                    <span className="d-flex fd-column">
                      <span className="d-flex fd-row">
                        {getTitleMapping(t)[policy.type]}
                        <span className="ml8 p-p tc-grey-600">
                          {getSubTitle(policy)}
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
          {hasExpiredPolicies &&
            (showExpired ? (
              <button
                type="button"
                className="d-block bg-transparent ds-interactive-component p-p fw-bold c-pointer mb8 mt24"
                onClick={hideExpiredPolicies}
              >
                {t('claims.create.hideExpiredButton.title', 'Expired policies')}
              </button>
            ) : (
              <button
                type="button"
                className="d-block bg-transparent ds-interactive-component p-p fw-bold c-pointer mb8 mt24 tc-grey-500"
                onClick={() => {
                  setShowExpired(true);
                }}
              >
                {t(
                  'claims.create.showExpiredButton.title',
                  'Show expired policies'
                )}
              </button>
            ))}
          {showExpired && (
            <div className="p-label-container mt16">
              {applicablePolicies
                .filter((policy) => !isPolicyActive(policy))
                .map((policy) => {
                  return (
                    <div className="mt8 wmx6" key={policy.id}>
                      <input
                        id={policy.id}
                        className="p-radio mr8"
                        type="radio"
                        onChange={() => setSelectedPolicy(policy)}
                        checked={policy.id === selectedPolicy?.id}
                      />
                      <label
                        htmlFor={policy.id}
                        className="p-label p-label--bordered pr16"
                      >
                        <span className="d-flex fd-column">
                          <span className="d-flex fd-row">
                            {getTitleMapping(t)[policy.type]}
                            <span className="ml8 p-p tc-grey-600">
                              {getSubTitle(policy)}
                            </span>
                          </span>
                          <div className="p-p--small">
                            {t('claims.create.expiredPolicy.date', {
                              defaultValue: 'Ended on {{date}}',
                              date: dayjs(policy.attributes.activeUntil).format(
                                DATE_FORMAT
                              ),
                            })}
                          </div>
                        </span>
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
          <button
            className={classNames('p-btn--primary ws3 mt24', styles.button)}
            onClick={onSubmit}
            data-cy="button-create-claim"
            disabled={!selectedPolicy}
            type="button"
          >
            {t('claims.create.submitButton.title', 'Continue')}
          </button>
        </div>
      </div>
    </div>
  );
};
