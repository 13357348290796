import { trackTaskEngineCardClicked } from '@getpopsure/analytics';
import * as Sentry from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useState } from 'react';

import { useSafeTranslation } from '../../../../shared/i18n';
import { updateTask } from '../../api/taskEngine.api';
import { CustomTask } from '../../components/CustomTask';
import { TASK_ENGINE_SENTRY_CATEGORY } from '../../constants';
import {
  PrivateHealthIbanUpdateTaskDescriptionSchema,
  Task,
  TaskEngineOptions,
} from '../../taskEngine.models';
import {
  HOW_DOES_PAYMENT_WORK_FAQ_URL,
  IbanPaymentMethod,
  MANUAL_PAYMENT_FAQ_URL,
  PROVIDE_IBAN_VIA_TALLY_URL,
} from './constants';

export const PrivateHealthIbanUpdate = ({
  task,
  options,
}: {
  task: Task;
  options?: TaskEngineOptions;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data: { paymentMethod: IbanPaymentMethod }) => {
      return updateTask({
        taskId: task.id,
        payload: {
          ...data,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
    },
  });
  const { t } = useSafeTranslation();

  const parsedTask = PrivateHealthIbanUpdateTaskDescriptionSchema.safeParse(
    task.description
  );

  if (!parsedTask.success) {
    Sentry.captureMessage(
      `${TASK_ENGINE_SENTRY_CATEGORY} Failed to parse bike receipts task`,
      {
        level: 'error',
        tags: {
          feature: TASK_ENGINE_SENTRY_CATEGORY,
        },
        extra: {
          task,
          validationErrors: parsedTask.error,
        },
      }
    );
    return null;
  }

  const {
    type,
    attributes: { fullName, startDate },
  } = parsedTask.data;

  const openModal = () => {
    trackTaskEngineCardClicked({
      task_type: type,
      task_id: task.id,
    });

    if (options?.onTaskClick) {
      options.onTaskClick(task);
    }

    setShowModal(true);
  };
  const closeModal = () => setShowModal(false);

  const handleProvideIban = () => {
    window.open(PROVIDE_IBAN_VIA_TALLY_URL, '_blank');
    mutation.mutate({ paymentMethod: 'PROVIDED_VIA_TALLY' });
  };

  const handleProvideLater = () => {
    window.open(MANUAL_PAYMENT_FAQ_URL, '_blank');
    mutation.mutate({ paymentMethod: 'MANUAL_PAYMENT' });
  };

  const startDateInMoreThanTwoWeeeks =
    dayjs(startDate).diff(dayjs(), 'days') >= 14;

  const description = startDateInMoreThanTwoWeeeks
    ? t(
        'taskEngine.privateHealthIbanUpdate.description.startDateInMoreThanTwoWeeeks',
        '<p>Set up the direct debit below no later than 2 weeks before your coverage begins. Alternatively you can make a <a href="{{manualPaymentFaq}}" target="_blank">manual payment</a> within 14 days after the coverage has started. Otherwise, <a href="{{howDoesPaymentWorkFaq}}" target="_blank">your coverage might be canceled</a></p>',
        {
          manualPaymentFaq: MANUAL_PAYMENT_FAQ_URL,
          howDoesPaymentWorkFaq: HOW_DOES_PAYMENT_WORK_FAQ_URL,
        }
      )
    : t(
        'taskEngine.privateHealthIbanUpdate.description.startDateInLessThanTwoWeeks',
        '<p>You can set up a direct debit below if you’d like future payments to be taken directly from your account, <strong>as long as you have no pending payment.</strong></p><p>If no payment has been made so far, please <a href="{{manualPaymentFaq}}" target="_blank">transfer</a> the amount manually within 14 days from your policy start. Otherwise, <a href="{{howDoesPaymentWorkFaq}}" target="_blank">your coverage might be canceled.</a></p>',
        {
          manualPaymentFaq: MANUAL_PAYMENT_FAQ_URL,
          howDoesPaymentWorkFaq: HOW_DOES_PAYMENT_WORK_FAQ_URL,
        }
      );
  const payManuallyButtonText = startDateInMoreThanTwoWeeeks
    ? t(
        'taskEngine.privateHealthIbanUpdate.modal.button.payManually',
        'Pay manually'
      )
    : t(
        'taskEngine.privateHealthIbanUpdate.modal.button.keepPayingManually',
        'Keep paying manually'
      );

  return (
    <CustomTask
      metadata={{
        title: t(
          'taskEngine.privateHealthIbanUpdate.title',
          'Set up direct debit'
        ),
        subtitle: t(
          'taskEngine.privateHealthIbanUpdate.subtitle',
          'Private health · {{fullName}}',
          { fullName }
        ),
        description,
      }}
      modal={{
        isOpen: showModal,
        loading: false,
        error: null,
        onOpen: openModal,
        onClose: closeModal,
      }}
      actions={[
        {
          id: 'provide-iban',
          type: 'BUTTON',
          attributes: {
            type: 'button',
            variant: 'filledColor',
            className: 'mr8',
            onClick: handleProvideIban,
            children: t(
              'taskEngine.privateHealthIbanUpdate.modal.button.provideIban',
              'Provide IBAN'
            ),
          },
        },
        {
          id: 'pay-manually',
          type: 'BUTTON',
          attributes: {
            type: 'button',
            variant: 'filledGray',
            onClick: handleProvideLater,
            children: payManuallyButtonText,
          },
        },
      ]}
    />
  );
};
