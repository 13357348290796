import { ZFutureDateString } from 'shared/models/validations';

import { CheckoutInfo } from '../paymentScreen.models';

export const isValidStartDate = (checkoutInfo: CheckoutInfo) => {
  let isValid = true;

  if (
    checkoutInfo.errors?.length &&
    checkoutInfo.errors[0].path === 'mainPolicy.startDate'
  ) {
    isValid = false;
  }

  if (checkoutInfo?.mainPolicy.startDate) {
    const startDateValidationResult = ZFutureDateString.safeParse(
      checkoutInfo?.mainPolicy.startDate
    );
    isValid = startDateValidationResult?.success && isValid;
  }

  return isValid;
};
