import {
  Header,
  QuestionnaireFormProps,
  QuestionnaireQuestions,
} from '@getpopsure/qnr-framework';
import { DateSelector, Input } from '@popsure/dirty-swan';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

type UpdateKeys = 'firstName' | 'lastName' | 'dateOfBirth' | 'gender';

type CoveredPersonEntry = {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
};

const isOver25 = (dateOfBirth: string): boolean => {
  return dayjs().diff(dayjs(dateOfBirth), 'years') >= 25;
};

const validateAgeConstraints = (
  coveredEntries: CoveredPersonEntry[]
): boolean => {
  return (
    coveredEntries.filter((coveredEntry) => isOver25(coveredEntry.dateOfBirth))
      .length <= 1
  );
};

const validateGenderPresence = (
  coveredEntries: CoveredPersonEntry[]
): boolean => {
  return coveredEntries
    .filter(({ dateOfBirth }) => isOver25(dateOfBirth))
    .every(({ gender }) => gender === 'MALE' || gender === 'FEMALE');
};

export const CoveredGroup = <Questionnaire extends QuestionnaireQuestions>({
  setValidForSubmission,
  onSubmitValue,
  value,
  title,
}: QuestionnaireFormProps<Questionnaire> & { title?: string }) => {
  const { t } = useSafeTranslation();
  const [covered, setCovered] = useState<CoveredPersonEntry[]>(() => {
    return (
      (value as CoveredPersonEntry[]) ?? [
        {
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          gender: '',
        },
      ]
    ).map((coveredPerson, idx) => {
      return {
        ...coveredPerson,
        id: `covered-person-${idx}`,
      };
    });
  });

  const onUpdateCoveredPerson = (key: UpdateKeys, val: string, id: string) => {
    setCovered((previousCovered) => {
      return previousCovered.map((previousCoveredPerson) => {
        if (previousCoveredPerson.id === id) {
          return { ...previousCoveredPerson, [key]: val };
        }
        return previousCoveredPerson;
      });
    });
  };

  const canAdd =
    covered.every(
      (coveredPerson) =>
        coveredPerson.firstName !== '' &&
        coveredPerson.lastName !== '' &&
        coveredPerson.dateOfBirth !== ''
    ) &&
    validateAgeConstraints(covered) &&
    validateGenderPresence(covered);

  const isValid = covered.length > 0 && covered.length <= 8 && canAdd;

  useEffect(() => {
    setValidForSubmission(isValid);
  }, [isValid, setValidForSubmission]);

  const handleSubmit = () => {
    if (isValid) {
      onSubmitValue(covered.map(({ id, ...coveredPerson }) => coveredPerson));
    }
  };

  const addCovered = () => {
    setCovered((prev) => {
      return [
        ...prev,
        {
          id: `covered-person-${prev.length + 1}`,
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          gender: '',
        },
      ];
    });
  };

  const removeCovered = (index: number) => {
    setCovered((prev) => {
      if (prev.length <= 1) {
        return prev;
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  return (
    <Header onSubmit={handleSubmit}>
      <h1 className="p-h1 my16">{title}</h1>
      <div>
        {covered.map((coveredPerson, index) => {
          return (
            <CoveredPerson
              key={coveredPerson.id}
              hideRemove={index === 0}
              {...coveredPerson}
              onChange={(key, val) =>
                onUpdateCoveredPerson(key, val, coveredPerson.id)
              }
              remove={() => removeCovered(index)}
            />
          );
        })}
        <AnimateHeight
          duration={300}
          height={!validateAgeConstraints(covered) ? 'auto' : 0}
        >
          <div className="p-notice p-notice--warning mt16 p-p">
            {t(
              'coveredGroup.maxAdults.error.msg',
              'Only one additional adult and seven children ages under 25 are allowed'
            )}
          </div>
        </AnimateHeight>
        <AnimateHeight
          duration={300}
          height={!validateGenderPresence(covered) ? 'auto' : 0}
        >
          <div className="p-notice p-notice--warning mt16 p-p">
            {t(
              'coveredGroup.gender.error.msg',
              'Gender information is required for your dependent above 25 years old'
            )}
          </div>
        </AnimateHeight>
        <div className="d-flex fd-column my16 gap16">
          <button
            className="p-btn--secondary"
            type="button"
            onClick={addCovered}
            disabled={!canAdd || covered.length >= 8}
          >
            {t('coveredGroup.btn.addAnother.cta', 'Add another')}
          </button>
        </div>
      </div>
    </Header>
  );
};

type CoveredPersonProps = {
  onChange: (key: UpdateKeys, value: string) => void;
  remove: () => void;
  hideRemove: boolean;
} & CoveredPersonEntry;

const CoveredPerson = ({
  id,
  firstName,
  lastName,
  dateOfBirth,
  gender,
  onChange,
  remove,
  hideRemove,
}: CoveredPersonProps) => {
  const { t } = useSafeTranslation();

  const GENDER_OPTIONS = [
    { value: 'MALE', label: t('coveredGroup.gender.male', 'Male') },
    { value: 'FEMALE', label: t('coveredGroup.gender.female', 'Female') },
  ];

  return (
    <div className="d-flex fd-column my16 gap16 w100">
      <div className="d-flex fd-column my8 gap8">
        <div className="p-h4">{t('coveredGroup.name.title', 'Name')}</div>
        <Input
          placeholder="First name"
          value={firstName}
          onChange={(e) => {
            onChange('firstName', e.target.value);
          }}
        />
        <Input
          placeholder="Last name"
          value={lastName}
          onChange={(e) => {
            onChange('lastName', e.target.value);
          }}
        />
      </div>

      <div className="d-flex fd-column my8 gap8">
        <div className="p-h4">
          {t('coveredGroup.dateOfBirth.title', 'Date of birth')}
        </div>
        <DateSelector
          onChange={(date: string) => {
            onChange('dateOfBirth', date);
            if (!isOver25(date)) {
              onChange('gender', '');
            }
          }}
          value={dateOfBirth}
          displayCalendar
          yearBoundaries={{
            min: dayjs().subtract(64, 'years').year(),
            max: dayjs().year(),
          }}
        />
      </div>

      {isOver25(dateOfBirth) && (
        <div className="d-flex fd-column my8 gap8">
          <div className="p-h4">{t('coveredGroup.gender.title', 'Gender')}</div>
          {GENDER_OPTIONS.map(({ value, label }) => (
            <div key={`${id}-${value}`}>
              <input
                id={`gender-${id}-${value}`}
                name={`gender-${id}`}
                className="p-radio"
                type="radio"
                value={value}
                onChange={() => onChange('gender', value)}
                checked={gender === value}
              />
              <label
                htmlFor={`gender-${id}-${value}`}
                className="p-label p-label--bordered"
              >
                {label}
              </label>
            </div>
          ))}
        </div>
      )}

      {!hideRemove && (
        <button className="p-btn--secondary" type="button" onClick={remove}>
          {t('coveredGroup.btn.remove.cta', 'Remove')}
        </button>
      )}

      <hr className="mt4 mb4" />
    </div>
  );
};
