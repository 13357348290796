/* eslint-disable react/no-danger */

import classNames from 'classnames';

import styles from './CustomTask.module.scss';

export const RichTextPreview = ({ html }: { html: string }) => {
  return (
    <div
      className={classNames('mb16 p-p', styles.richTextContainer)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
