import {
  type BlocksContent,
  BlocksRenderer,
} from '@strapi/blocks-react-renderer';

import styles from './StrapiRichText.module.scss';

const StrapiRichText = ({
  richText,
  paragraphClassName,
}: {
  richText: BlocksContent;
  paragraphClassName?: string;
}) => {
  return (
    <BlocksRenderer
      content={richText}
      blocks={{
        paragraph: ({ children }) => (
          <p className={paragraphClassName ?? 'p-p mt8'}>{children}</p>
        ),
        heading: ({ children, level }) => {
          switch (level) {
            case 1:
              return <h1 className="p-h1">{children}</h1>;
            case 2:
              return <h2 className="p-h2">{children}</h2>;
            case 3:
              return <h3 className="p-h3">{children}</h3>;
            default:
              throw Error(`Unsupported heading level ${level}`);
          }
        },
        link: ({ url, children }) => {
          return (
            <a className="p-a fw-bold" href={url ?? ''}>
              {/* RichText serializes children as string */}
              {children as unknown as string}
            </a>
          );
        },
        list: ({ children, format }) => {
          return format === 'ordered' ? (
            <ol className={`p-p mt8 ${styles.olist}`}>{children}</ol>
          ) : (
            <ul className={`p-p mt8 ${styles.ulist}`}>{children}</ul>
          );
        },
        code: () => {
          throw Error('Code node was not implemented');
        },
        quote: () => {
          throw Error('Quote node was not implemented');
        },
      }}
      modifiers={{
        bold: ({ children }) => <span className="fw-bold">{children}</span>,
        italic: ({ children }) => <span className="fs-italic">{children}</span>,
      }}
    />
  );
};

export default StrapiRichText;
