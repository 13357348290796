import { SignupQuestionnaireType } from 'SignupQuestionnaire/SignupQuestionnaire';

import { NameWithTerms } from './components/NameWithTerms';
import { RegionSelector } from './components/RegionSelector';
import { SignInGroupIds, SignInQuestions } from './models';

export const SignInComponents = {
  NAME_WITH_TERMS: NameWithTerms,
  REGION: RegionSelector,
} as const;

export type SignInQuestionnaire = SignupQuestionnaireType<
  SignInQuestions,
  SignInGroupIds,
  typeof SignInComponents
>;

export const getTranslatedQuestionnaire = (
  isLoggedIn: boolean
): SignInQuestionnaire => [
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'userCreation',
      showTerms: false,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'question',
  },
  {
    id: 'name',
    required: !isLoggedIn,
    type: 'NAME_WITH_TERMS',
    groupId: 'question',
    props: {},
    screen: {
      layout: 'Standalone',
    },
  },
  {
    id: 'region',
    required: !isLoggedIn,
    type: 'REGION',
    groupId: 'question',
    props: {},
    screen: {
      layout: 'Standalone',
    },
  },
];
