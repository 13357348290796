import { isVerticalWithCheckoutDocuments } from 'features/checkoutDocuments/models';
import { InsuranceTypes } from 'models/insurances/types';

export const checkHasCheckoutDocuments = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configuration: any,
  checkoutInsuranceType?: InsuranceTypes
) => {
  if (
    configuration &&
    typeof configuration === 'object' &&
    typeof configuration.hasCheckoutDocuments === 'boolean'
  ) {
    return configuration.hasCheckoutDocuments;
  }

  if (!checkoutInsuranceType) {
    return false;
  }

  return isVerticalWithCheckoutDocuments(checkoutInsuranceType);
};
