import { AxiosInstance } from 'axios';

import {
  Checkout,
  CheckoutInfo,
  CheckoutInfoRequest,
  CheckoutResult,
} from './paymentScreen.models';

type ProcessAndConfirmPayload = {
  paymentMethodId: string;
  localeId?: string;
  referralCode?: string;
  configurationQueryParam?: string;
};

type FinalizePayload = {
  paymentMethodId: string;
  paymentSucceeded: boolean;
};

export function startCheckout(
  network: AxiosInstance,
  data: CheckoutInfoRequest
) {
  return network.post<CheckoutInfo>('/checkout/generic/checkouts', data);
}

export function linkToStripe(network: AxiosInstance) {
  return network.post<unknown>('/account/link_to_stripe');
}

export function processCheckout(
  network: AxiosInstance,
  checkoutId: string,
  payload: ProcessAndConfirmPayload
) {
  return network.post<CheckoutResult>(
    `/checkout/generic/checkouts/${checkoutId}/process`,
    payload
  );
}

export function confirmCheckout(
  network: AxiosInstance,
  checkoutId: string,
  payload: ProcessAndConfirmPayload
) {
  return network.post<CheckoutResult>(
    `/checkout/generic/checkouts/${checkoutId}/confirm`,
    payload
  );
}

export function finalizeCheckout(
  network: AxiosInstance,
  checkoutId: string,
  payload: FinalizePayload
) {
  return network.post<CheckoutResult>(
    `/checkout/generic/checkouts/${checkoutId}/finalize`,
    payload
  );
}

export function getCheckoutInfo(network: AxiosInstance, checkoutId: string) {
  return network.get<Checkout>(`/checkout/generic/checkouts/${checkoutId}`);
}

export function getCheckoutQuestionnaire(
  network: AxiosInstance,
  questionnaireId: string
) {
  return network.get<Record<string, unknown>>(
    `/checkout/generic/checkouts/questionnaires/${questionnaireId}`
  );
}

export function getCheckoutQuote(network: AxiosInstance, quoteId: string) {
  return network.get<Record<string, unknown>>(
    `/checkout/generic/checkouts/quotes/${quoteId}`
  );
}
