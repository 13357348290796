import { featherIcon } from 'icons';

import { QuoteConfig, QuoteCustomization, QuoteResponse } from './Quote.models';

export const strapiQuote: QuoteConfig = {
  title: 'Test Quote Page',
  headerColor: '#FAF5EB',
  headerImage: {
    alt: 'Dog liability insurance',
    src: 'https://cms.cdn.feather-insurance.com/Dog_liability_cb5f80110f/Dog_liability_cb5f80110f.png',
  },
  footer: {
    bookACallUrl: 'https://calendly.com/feather-insurance',
    secondaryAction: 'Visit our FAQ',
    secondaryActionLink: 'https://feather-insurance.com/',
  },
  addons: {
    id: 1,
    title: 'Extend coverage',
    addonItem: [
      {
        id: 1,
        addonKey: 'US_CARIBBEAN',
        title: 'Coverage to the US and Caribbean',
        description:
          'Medical care in the US and the Caribbean can be very expensive. If you plan to travel frequently or stay for long periods in these regions, extending your coverage can help protect you from unexpected medical expenses.',
      },
    ],
  },
  faq: [
    {
      id: '2',
      question: 'How does dental insurance work?',
      answer:
        "When you receive dental treatments, you'll have to pay for it upfront. Your public insurance might cover some of the treatment, but you’ll only be billed for what’s leftover. You just need to submit the signed bill in your Feather account under the “My policies” page. You’ll be reimbursed directly by us in 3 - 6 weeks depending on the treatment and amount of the claim.",
    },
    {
      id: '1',
      question:
        'Can I cover other family members as part of a dental insurance policy?',
      answer:
        'If you would like to insure other members of your family, such as your partner or any dependents, then you will need to purchase an individual policy for each person. Dependents will need to have a parental guardian listed on the policy, and that will be asked during sign up.',
    },
  ],
  table: {
    title: 'table-test',
    collapsibleSections: false,
    hideDetails: false,
    tableData: [
      {
        section: {
          title: '',
        },
        rows: [
          [
            {
              cellId: '1_header',
              text: 'Choose a plan',
              description: null,
              modalContent: null,
            },
            {
              cellId: '1_0_button',
              type: 'BUTTON',
              buttonCaption: 'Basic',
              price: null,
              disabled: true,
              onClick: () => {},
            },
            {
              cellId: '1_1_button',
              type: 'BUTTON',
              buttonCaption: 'Advanced',
              price: null,
              disabled: true,
              onClick: () => {},
            },
          ],
          [
            {
              cellId: '2_header',
              text: 'No waiting periods',
              description: null,
              modalContent: [
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Once you sign up for dental insurance, your application will be processed and approved within 1-3 business days. After that, you can immediately start using your full benefits at the dentist.',
                      type: 'text',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: '',
                      type: 'text',
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  children: [
                    {
                      text: 'Just keep in mind that any procedures for which you had an appointment or planned before applying for dental insurance won’t be covered.',
                      type: 'text',
                    },
                  ],
                },
              ],
            },
            {
              cellId: '2_0_row',
              modalContent: null,
              checkmarkValue: true,
              hideProgressBar: false,
            },
            {
              cellId: '2_1_row',
              modalContent: null,
              checkmarkValue: true,
              hideProgressBar: false,
            },
          ],
          [
            {
              cellId: '3_header',
              text: 'Purely cosmetic procedures',
              description: null,
              modalContent: null,
            },
            {
              cellId: '3_0_row',
              modalContent: null,
              checkmarkValue: false,
              hideProgressBar: false,
            },
            {
              cellId: '3_1_row',
              modalContent: null,
              checkmarkValue: false,
              hideProgressBar: false,
            },
          ],
        ],
      },
    ],
  },
  covered: {
    id: 1,
    title: 'Coverage highlights',
    card: [
      {
        id: 2,
        title: 'Injuries to other people damage',
        description:
          'If your dog gets excited and runs into someone, causing them to fall and hurt themselves, your policy will cover the expenses. your policy will cover.',
        icon: { src: featherIcon, alt: 'Icon' },
      },
      {
        id: 1,
        title: 'Damage to rented apartments',
        description:
          'Did your dog decide to chew up your rented furniture? Relax and keep your security deposit when you move out!',
        icon: { src: featherIcon, alt: 'Icon' },
      },
      {
        id: 3,
        title: 'Property damage',
        description:
          "You'll be covered if your dog damages someone's belongings (E,g, your friends carpet or laptop)",
        icon: { src: featherIcon, alt: 'Icon' },
      },
      {
        id: 4,
        title: 'Financial loss',
        description:
          "If your dog bites the mailperson and can't work after that, dog liability insurance will cover you.",
        icon: { src: featherIcon, alt: 'Icon' },
      },
    ],
  },
  notCovered: {
    id: 2,
    title: 'Not covered',
    card: [
      {
        id: 5,
        title: 'Damage to your own property',
        description:
          'Replacement or repair of things you own is not covered by dog liability.',
        icon: { src: featherIcon, alt: 'Icon' },
      },
      {
        id: 6,
        title: 'Not rented electronics',
        description:
          'If your dog damages an electronic device you rented from someone else, it’s not covered.',
        icon: { src: featherIcon, alt: 'Icon' },
      },
    ],
  },
  quoteOptions: [
    {
      id: 1,
      __component: 'quote-page.customisation-with-switch',
      title: 'Choose your out-of-pocket amount',
      description:
        'This is the amount you pay out-of-pocket for healthcare services before your insurance starts to pay, also called a yearly deductible, or excess . A higher deductible means lower monthly cost, while a lower deductible reduces upfront expenses when you need care.\n\nIf you are applying for a visa or residence permit, we suggest choosing a deductible of no more than €750, as this is typically the maximum accepted by the authorities.',
      type: 'switch',
      optionKey: 'deductible',
      customizationOptions: [
        {
          id: 1,
          label: '€0',
          value: '0',
        },
        {
          id: 2,
          label: '€750',
          value: '750',
        },
        {
          id: 3,
          label: '€5,750',
          value: '5750',
        },
      ],
    },
    {
      id: 2,
      __component: 'quote-page.customisation-with-switch',
      title: 'Choose another amount',
      description:
        '\nIf you are applying** for a visa or residence permit**, we suggest choosing a deductible of no more than €750, as this is typically the maximum accepted by the authorities.',
      type: 'checkbox',
      optionKey: 'excess',
      customizationOptions: [
        {
          id: 4,
          label: 'Yes',
          value: 'YES',
        },
        {
          id: 6,
          label: 'No',
          value: 'NO',
        },
        {
          id: 5,
          label: 'Maybe',
          value: 'Maybe',
        },
      ],
    },
    {
      id: 2,
      __component: 'quote-page.quote-customization-slider',
      title: 'The other amount',
      description:
        '\nIf you are applying** for a visa or residence permit**, we suggest choosing a deductible of no more than €750, as this is typically the maximum accepted by the authorities.\n\n\nIf you are applying** for a visa or residence permit**, we suggest choosing a deductible of no more than €750, as this is typically the maximum accepted by the authorities.',
      minimumValue: 0,
      maximumValue: 1000,
      optionKey: 'otherAmount',
      sliderLabel: 'Your Payout',
      sliderSubLabel: 'per month',
    },
  ],
};

export const quotes: QuoteResponse[] = [
  {
    id: '2',
    price: 5.58,
    planId: 'BASIC',
    name: 'Basic',
    quoteOptions: {},
    addons: [],
  },
  {
    id: '1',
    price: 6.9,
    planId: 'ADVANCED',
    quoteOptions: {},
    addons: [],
  },
];

export const quoteCustomization: QuoteCustomization = {
  selectedPlan: 'BASIC',
  selectedQuoteOptions: {},
  selectedAddons: [],
};
