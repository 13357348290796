import { useWindowSize } from 'hooks/useWindowSize';
import { Helmet } from 'react-helmet';

export const ExpatLT2025TallyForm = () => {
  const size = useWindowSize();

  return (
    <>
      <Helmet>
        <script async src="https://tally.so/widgets/embed.js" />
      </Helmet>
      <iframe
        width={size.width ?? '100%'}
        height={size.height ?? '100%'}
        data-tally-src="https://tally.so/r/mK8Qdg"
        title="Long term expat health insurance"
        data-cy="tally-iframe"
      />
    </>
  );
};
