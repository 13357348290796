import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PolicyDetailsConfig } from 'features/paymentScreen/components/PolicyDetails/utils';
import { capitalizeSingleWord } from 'shared/util/capitalizeSingleWord';
import { formatCurrencyWithSpace } from 'shared/util/formatCurrencyWithSpace';
import { isName } from 'shared/validators';

dayjs.extend(customParseFormat);

const DATE_FORMAT = 'DD MMM YYYY';

export type PolicyDetailsData = {
  title: string;
  questionId: string;
  dataType?: 'CURRENCY' | 'YEARS';
  additionalInfo?: { title: string; description: string };
  noBreak?: boolean;
};

export type PolicyDetailsConfiguration = {
  policyDetails: PolicyDetailsData[];
  otherSections?: {
    title: string;
    rows: PolicyDetailsData[];
  }[];
};

const isDate = (answer: unknown): answer is Date => {
  return (
    typeof answer === 'string' && dayjs(answer, 'YYYY-MM-DD', true).isValid()
  );
};

const processPolicyDetailsQuestionValue = (
  answer: unknown,
  dataType?: 'CURRENCY' | 'YEARS'
) => {
  if (isName(answer)) {
    return `${answer.firstName} ${answer.lastName}`;
  }

  if (isDate(answer)) {
    return dayjs(answer).format(DATE_FORMAT);
  }

  if (typeof answer === 'number' && dataType === 'CURRENCY') {
    return formatCurrencyWithSpace(answer, 2);
  }

  if (typeof answer === 'number' && dataType === 'YEARS') {
    return `${answer} years`;
  }

  if (typeof answer === 'string') {
    return capitalizeSingleWord(answer);
  }

  return answer ? String(answer) : '';
};

export const processPolicyDetailsData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaireAnswers: Record<string, any>,
  policyDetailsConfiguration: PolicyDetailsConfiguration
): PolicyDetailsConfig => {
  return {
    totalPrice: questionnaireAnswers.quote.price,
    policyDetails: policyDetailsConfiguration.policyDetails.map((config) => ({
      ...config,
      value: processPolicyDetailsQuestionValue(
        questionnaireAnswers[config.questionId] ??
          questionnaireAnswers.quote?.[config.questionId],
        config.dataType
      ),
    })),
    otherSections: policyDetailsConfiguration.otherSections?.map((config) => ({
      ...config,
      cells: config.rows.map((rowConfig) => ({
        ...rowConfig,
        value: processPolicyDetailsQuestionValue(
          questionnaireAnswers[rowConfig.questionId] ??
            questionnaireAnswers.quote?.[rowConfig.questionId],
          rowConfig.dataType
        ),
      })),
    })),
  };
};
