import { Address } from '@getpopsure/public-models';
import { Question, QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import dayjs from 'dayjs';
import { GeneralQuestion } from 'features/generalPolicy/model';
import { inlinedFormattedAddress } from 'models/address';
import { Name } from 'models/user';
import { FORMATTED_DATE_FORMAT } from 'shared/models/validations';
import { SignupSpecificComponents } from 'SignupQuestionnaire/SignupQuestionnaire';

const isAddress = (answer: unknown): answer is Address => {
  return (
    typeof answer === 'object' &&
    answer !== null &&
    'street' in answer &&
    'houseNumber' in answer &&
    'postcode' in answer &&
    'city' in answer &&
    'country' in answer
  );
};

const isName = (answer: unknown): answer is Name => {
  return (
    typeof answer === 'object' &&
    answer !== null &&
    'firstName' in answer &&
    'lastName' in answer
  );
};

const getQuestionCheckoutCell = (
  answer: unknown,
  question: Question<
    QuestionnaireQuestions,
    unknown,
    typeof SignupSpecificComponents
  >
) => {
  if (question.type === 'ADDRESS' && isAddress(answer)) {
    return inlinedFormattedAddress(answer);
  }

  if (question.type === 'NAME' && isName(answer)) {
    return `${answer.firstName} ${answer.lastName}`;
  }

  if (
    (question.type === 'RADIO' || question.type === 'CHECKBOX') &&
    typeof answer === 'string'
  ) {
    return question.props.mapValue[answer];
  }

  if (question.type === 'DATE' && typeof answer === 'string') {
    return dayjs(answer).format(FORMATTED_DATE_FORMAT);
  }

  return answer;
};

export const getCheckoutCells = (
  title: string,
  questionId: string,
  questions: GeneralQuestion,
  answers: QuestionnaireQuestions
) => {
  const question = questions.find((q) => q.id === questionId);
  const answer = answers[questionId];

  if (!question || !answer) {
    return { title, value: null };
  }

  return {
    title,
    value: getQuestionCheckoutCell(answer, question),
  };
};
