import Session from '@getpopsure/session';
import { storeGenericQuestionnaireAnswer } from 'actions/genericQuestionnaire';
import routes from 'constants/routes';
import Questionnaire from 'features/questionnaireFramework';
import { useQueryParamValue } from 'hooks/useQueryParamValue';
import { UserRegistrationInfo } from 'models/genericQuestionnaire';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSafeTranslation } from 'shared/i18n';

import { getTranslatedQuestionnaire } from './questionnaire';
import { getSignUpAnswers } from './selectors';

export const SignupRouting = () => {
  const dispatch = useDispatch();
  const answers = useSelector(getSignUpAnswers) || {};
  const redirectPath = useQueryParamValue('redirect');
  const history = useHistory();
  const { t } = useSafeTranslation();

  useEffect(() => {
    if (Session.isAuthenticated) {
      history.push(
        redirectPath ?? answers?.redirectPath ?? routes.me.policies.path
      );
    }
  }, [Session.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (redirectPath !== null) {
      dispatch(
        storeGenericQuestionnaireAnswer('userCreation', { redirectPath })
      );
    }
  }, [redirectPath, dispatch]);

  const handleSubmitAnswer = (answer: Partial<UserRegistrationInfo>) => {
    dispatch(storeGenericQuestionnaireAnswer('userCreation', answer));
  };

  return (
    <Questionnaire
      featureName="Signup"
      questionnaire={getTranslatedQuestionnaire({
        loggedIn: Session.isAuthenticated,
        t,
      })}
      questionnaireAnswers={answers ?? {}}
      handleAnswerQuestion={handleSubmitAnswer}
    />
  );
};
