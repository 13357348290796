import { QuestionnaireQuestions } from '@getpopsure/qnr-framework';
import {
  removeGenericQuestionnaireAnswer,
  storeGenericQuestionnaireAnswer,
} from 'actions/genericQuestionnaire';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';

interface StateManagementProps {
  questionnaireAnswers: Partial<QuestionnaireQuestions>;
  updateQuestionnaireAnswers: (data: Partial<QuestionnaireQuestions>) => void;
  removeQuestionnaireAnswers: (data: string[]) => void;
}

const useLocalStateManagement = (): StateManagementProps => {
  const [questionnaireAnswers, setQuestionnaireAnswers] =
    useState<QuestionnaireQuestions>({});

  const updateQuestionnaireAnswers = (data: QuestionnaireQuestions) => {
    setQuestionnaireAnswers((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const removeQuestionnaireAnswers = (questionnaireIds: string[] = []) => {
    setQuestionnaireAnswers((prev) => {
      return Object.entries(prev).reduce((acc, [id, value]) => {
        if (questionnaireIds.includes(id)) {
          return acc;
        }
        acc[id] = value;
        return acc;
      }, {} as QuestionnaireQuestions);
    });
  };

  return {
    questionnaireAnswers,
    updateQuestionnaireAnswers,
    removeQuestionnaireAnswers,
  };
};

export const useReduxStateManagement = (
  insuranceType: string
): StateManagementProps => {
  // For now we need to connect this to Redux until we can refactor the checkout
  // to be independent of any vertical specific information and Redux.
  const dispatch = useDispatch();

  const questionnaireAnswers =
    useSelector(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (state: AppState) => state.genericQuestionnaire[insuranceType]
    ) || {};

  const updateQuestionnaireAnswers = (
    data: Partial<QuestionnaireQuestions>
  ) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      storeGenericQuestionnaireAnswer(insuranceType, data)
    );
  };

  const removeQuestionnaireAnswers = (questionIds: string[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(removeGenericQuestionnaireAnswer(insuranceType, questionIds));
  };

  return {
    questionnaireAnswers,
    updateQuestionnaireAnswers,
    removeQuestionnaireAnswers,
  };
};

export const useStateManagement = (
  insuranceType: string,
  isPreview = false
): StateManagementProps => {
  const localStateManagement = useLocalStateManagement();
  const reduxStateManagement = useReduxStateManagement(insuranceType);

  return isPreview ? localStateManagement : reduxStateManagement;
};
