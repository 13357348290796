import { website } from '@getpopsure/private-constants';
import { Region } from '@getpopsure/public-models';
import dayjs from 'dayjs';
import { imageTypeMapping } from 'models/insurances/types/mapping';
import { TFunction } from 'shared/i18n/utils';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import {
  arrivalDateMaxFiveYearsInThePastValidator,
  arrivalDateOneYearInTheFutureValidator,
  coverageOneYearInTheFutureValidator,
} from 'SignupQuestionnaire/shared/customValidators';

import { createDependentPostQuote } from '../actions';
import { PolicyDetails } from '../components/policyDetails';
import { QuoteProcessing } from '../components/Processing/Processing';
import { Quote } from '../components/Quote/Quote';
import {
  ExpatEuDependent,
  ExpatEuGroupIds,
  genderMapping,
  occupationMapping,
} from '../models';

export const DATE_FORMAT = 'DD MMM YYYY';

export const getEarliestStartDate = (arrivalDate?: string) => {
  const tomorrow = dayjs().add(1, 'day');

  const earliestStartDate = dayjs(tomorrow).isAfter(arrivalDate)
    ? tomorrow
    : arrivalDate;

  return dayjs(earliestStartDate).format(DATE_FORMAT);
};

export const ExpatEuDependentComponents = {
  QUOTE_PROCESSING: QuoteProcessing,
  QUOTE_PAGE: Quote,
} as const;

export type ExpatEuDependentQuestionnaire = SignupQuestionnaireType<
  ExpatEuDependent,
  ExpatEuGroupIds,
  typeof ExpatEuDependentComponents
>;

export const getTranslatedQuestionnaire = (
  t: TFunction,
  region: string,
  regionId: Region,
  continueFromBlocker: () => void,
  mainPolicyId: string
): ExpatEuDependentQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'CUSTOM_INTRO',
    props: {
      title: t(
        'expatEu.qnr.dependent.preQuote.intro.title',
        "Hey! Let's cover your loved one"
      ),
      subTitle: t(
        'expatEu.qnr.dependent.preQuote.intro.subTitle',
        'You can simply answer the following questions for them and their new policy will be added to your account.'
      ),
      iconTitle: t(
        'expatEu.qnr.dependent.preQuote.intro.iconTitle',
        'Expat health insurance'
      ),
    },
    screen: {
      continueButtonText: 'Get started',
    },
    groupId: 'signup',
  },
  {
    id: 'dateOfBirth',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'add', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'expatEu.qnr.dependent.preQuote.dateOfBirth.title',
        'When were they born?  '
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatEu.qnr.dependent.dateOfBirth.validation.errorMessage',
            'You can’t select a birth date in the future. Please make sure the date of birth is correct.'
          ),
        },
      },
    ],
    groupId: 'preQuote',
  },
  {
    id: 'ageIs75AndAboveBlocker',
    type: 'BLOCKER',
    props: {
      title: t(
        'expatEu.qnr.dependent.preQuote.ageIs75AndAboveBlocker.title',
        'Unfortunately...'
      ),
      iconType: 'SHIELD',
      description: t(
        'expatEu.qnr.dependent.preQuote.ageIs75AndAboveBlocker.description',
        'Because they are over 74 years old, they aren’t eligible for expat health insurance.\n\nIf you have questions, please feel free to contact us.'
      ),
      buttonProps: [
        {
          type: 'href',
          href: website.support,
          caption: t(
            'expatEu.qnr.dependent.preQuote.ageIs75AndAboveBlocker.button',
            'Get in touch'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'arrivalDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 5 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    screen: {
      question: t('expatEu.qnr.dependent.preQuote.arrivalDate.title', {
        defaultValue: 'What’s their (planned) arrival date in {{region}}?',
        region,
      }),
      additionalInfo: {
        title: t(
          'expatEu.qnr.dependent.preQuote.arrivalDate.additionalInfo.title',
          'Why is the date important?'
        ),
        description: t(
          'expatEu.qnr.dependent.preQuote.arrivalDate.additionalInfo.description',
          'The maximum length of coverage on expat health insurance is 5 years.'
        ),
      },
    },
    validations: [
      arrivalDateOneYearInTheFutureValidator(t),
      arrivalDateMaxFiveYearsInThePastValidator(t),
    ],
    groupId: 'signup',
  },
  {
    id: 'occupation',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: occupationMapping(t),
    },
    screen: {
      question: t('expatEu.qnr.dependent.preQuote.occupation.title', {
        defaultValue: 'What’s their main occupation in {{region}}?',
        region,
      }),
    },
    groupId: 'preQuote',
  },
  {
    id: 'regionOfEmployment',
    type: 'RADIO',
    props: {
      mapValue: {
        YES: t('expatEu.qnr.dependent.preQuote.regionEmployment.yes', {
          defaultValue: 'Yes, in {{region}}',
          region,
        }),
        NO: t(
          'expatEu.qnr.dependent.preQuote.regionEmployment.no',
          'No, elsewhere.'
        ),
      },
    },
    screen: {
      question: t('expatEu.qnr.dependent.preQuote.regionOfEmployment.title', {
        defaultValue: 'Are they employed in {{region}}?',
        region,
      }),
      additionalInfo: {
        title: t(
          'expatEu.qnr.dependent.preQuote.regionOfEmployment.additionalInfo.title',
          'Working for an international company?'
        ),
        description: t(
          'expatEu.qnr.dependent.preQuote.regionOfEmployment.additionalInfo.description',
          {
            defaultValue:
              'If the company they work for has an office in {{region}} and they have an employment contract from {{region}}, they are considered to be employed in {{region}}.',
            region,
          }
        ),
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'employedInRegionBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: t(
        'expatEu.qnr.dependent.preQuote.employedInRegionBlocker.title',
        'Expat health insurance might not be right for them'
      ),
      description: t(
        'expatEu.qnr.dependent.preQuote.employedInRegionBlocker.description',
        {
          defaultValue:
            'Expat health insurance is typically not accepted by employers in {{region}}. It is only a temporary option for new arrivals in {{region}}. It does not offer full health insurance coverage of cover pre-existing conditions.',
          region,
        }
      ),
      buttonProps: [
        {
          type: 'button',
          onClick: continueFromBlocker,
          caption: t(
            'expatEu.qnr.dependent.preQuote.employedInRegionBlocker.button.cta',
            'Continue anyway'
          ),
        },
      ],
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quoteOptions',
    required: true,
    type: 'QUOTE_PROCESSING',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    required: true,
    type: 'QUOTE_PAGE',
    props: {},
    screen: {
      question: '',
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'INTRO',
    props: {
      title: t(
        'expatEu.qnr.dependent.signup.signupIntro.title',
        "Great! Let's sign them up"
      ),
      subTitle: t(
        'expatEu.qnr.dependent.signup.signupIntro.subTitle',
        'Just 6 questions, it takes about 1-2 minutes.'
      ),
    },
    screen: {
      continueButtonText: t(
        'expatEu.qnr.dependent.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'expatEu.qnr.dependent.signup.name.title',
        "What's their name?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: t(
        'expatEu.qnr.dependent.signup.gender.title',
        'What is their gender?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'citizenship',
    required: true,
    type: 'COUNTRY_MULTI',
    props: {},
    screen: {
      question: t(
        'expatEu.qnr.dependent.signup.citizenship.title',
        'Which countries do they hold a passport from?'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'lastPermanentResidency',
    required: true,
    type: 'COUNTRY_SINGLE',
    props: {},
    screen: {
      question: t('expatEu.qnr.dependent.signup.lastPermanentResidency.title', {
        defaultValue:
          'What was their country of residence before arriving in {{ region }}?',
        region,
      }),
    },
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 5 },
      },
    },
    screen: {
      question: t(
        'expatEu.qnr.dependent.signup.startDate.title',
        'When would you like their coverage to start?'
      ),
    },
    validations: [
      {
        op: 'Custom',
        fn: (answer, { arrivalDate }: Partial<ExpatEuDependent>) => {
          const startDate = String(answer);
          return (
            dayjs().isBefore(startDate) &&
            (dayjs(arrivalDate).isSame(startDate) ||
              dayjs(arrivalDate).isBefore(startDate))
          );
        },
        msg: (_, answers) => {
          const { arrivalDate } = answers as Partial<ExpatEuDependent>;
          const earliestStartDate = getEarliestStartDate(arrivalDate);

          return {
            type: 'Error',
            msg: t('expatEu.qnr.dependent.signup.startDate.errorMessage', {
              defaultValue:
                'Coverage start date should be after their arrival date in {{region}} ({{date}})',
              date: earliestStartDate,
              region,
            }),
          };
        },
      },
      coverageOneYearInTheFutureValidator(t),
    ],
    groupId: 'signup',
  },
  {
    id: 'isMainPolicyTheLegalGuardian',
    type: 'BOOLEAN',
    screen: {
      question: t('qnr', 'Are you their legal guardian / parent?'),
    },
    props: {},
    groupId: 'signup',
  },
  {
    id: 'legalGuardianName',
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'expatEu.qnr.dependent.signup.legalGuardianName.title',
        "What's the name of their parent or legal guardian?"
      ),
      description: t(
        'expatEu.qnr.dependent.signup.legalGuardianName.description',
        "As they are under 18 years old, we need their parent / legal guardian's details to be able to sign you up."
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianDateOfBirth',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: t(
        'expatEu.qnr.dependent.signup.legalGuardianDateOfBirth.title',
        'When were they born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'year',
          value: 18,
        },
        msg: {
          type: 'Error',
          value: t(
            'expatEu.qnr.dependent.signup.legalGuardianDateOfBirth.errorMessage',
            'Legal guardian / parent should be over 18 years old.\n\nPlease make sure the birth date is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'legalGuardianEmail',
    type: 'EMAIL_BASIC',
    props: {},
    screen: {
      question: t(
        'expatEu.qnr.dependent.signup.legalGuardianEmail.email.title',
        "What's their email address?"
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'review',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'CREATE_POST_QUOTE_SUBMIT_INFO',
      reviewValues: [
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.plan.title',
            'Selected plan'
          ),
          value: { type: 'Strings', key: 'quote', keys: ['planId'] },
          path: 'quote',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.name.title',
            "Policy holder's name"
          ),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.dateOfBirth.title',
            'Date of birth'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'dateOfBirth' },
          path: 'dateOfBirth',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.gender.title',
            'Gender'
          ),
          value: {
            type: 'String',
            valueMap: genderMapping(t),
            key: 'gender',
          },
          path: 'gender',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.occupation.title',
            'Occupation'
          ),
          value: {
            type: 'String',
            valueMap: occupationMapping(t),
            key: 'occupation',
          },
          path: 'occupation',
        },
        {
          title: t('expatEu.qnr.dependent.signup.review.arrivalDate.title', {
            defaultValue: 'Arrival date in {{region}}',
            region,
          }),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'arrivalDate' },
          path: 'arrivalDate',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.citizenship.title',
            'Citizenship'
          ),
          value: { type: 'Array', pick: ['name'], key: 'citizenship' },
          path: 'citizenship',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.lastPermanentResidency.title',
            'Last permanent residency'
          ),
          value: {
            type: 'Strings',
            key: 'lastPermanentResidency',
            keys: ['name'],
          },
          path: 'lastPermanentResidency',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.startDate.title',
            'Coverage start date'
          ),
          value: { type: 'Date', format: 'DD MMM YYYY', key: 'startDate' },
          path: 'startDate',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.isMainPolicyTheLegalGuardian.title',
            'The main policy holder is the parent / legal guardian'
          ),
          value: { type: 'Boolean', key: 'isMainPolicyTheLegalGuardian' },
          path: 'isMainPolicyTheLegalGuardian',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.legalGuardianName',
            "Parent / legal guardian's name"
          ),
          value: {
            type: 'Strings',
            key: 'legalGuardianName',
            keys: ['firstName', 'lastName'],
          },
          path: 'legalGuardianName',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.legalGuardianDateOfBirth',
            "Parent / legal guardian's date of birth"
          ),
          value: {
            type: 'Date',
            key: 'legalGuardianDateOfBirth',
            format: 'DD MMM YYYY',
          },
          path: 'legalGuardianDateOfBirth',
        },
        {
          title: t(
            'expatEu.qnr.dependent.signup.review.legalGuardianEmail',
            "Parent / legal guardian's email"
          ),
          value: { type: 'String', key: 'legalGuardianEmail' },
          path: 'legalGuardianEmail',
        },
      ],
      verticalId: 'expatEu',
      insuranceType: 'INCOMING_EU',
      region: regionId,
      createPostQuote: () => createDependentPostQuote(t),
      confirmationText: t(
        'expatEu.qnr.dependent.signup.review.confirmation',
        'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered.'
      ),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'CHECKOUT',
    props: {
      type: 'INCOMING_EU',
      PolicyDetails,
      checkoutIcon: imageTypeMapping.INCOMING_EU,
      additionalPolicyData: {
        regionOfPurchase: regionId,
      },
      additionalData: {
        type: 'Dependent',
      },
      mainPolicyId,
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
