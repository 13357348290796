import classnames from 'classnames';
import Markdown from 'components/markdown';
import { areDocumentsValid } from 'features/checkoutDocuments/actions';
import { DocumentsReview } from 'features/checkoutDocuments/components/DocumentsReview';
import { CheckoutDocument } from 'features/checkoutDocuments/models';
import { PaymentOption } from 'features/paymentMethodSelector/paymentMethodSelector.models';
import { PaymentMethod } from 'features/paymentScreen/components/PaymentMethod/PaymentMethod.view';
import {
  PolicyDetails,
  PolicyDetailsProps,
} from 'features/paymentScreen/components/PolicyDetails/PolicyDetails';
import PolicyHolder from 'features/paymentScreen/components/PolicyHolder';
import { PriceInfo } from 'features/paymentScreen/paymentScreen.models';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import { useSafeTranslation } from 'shared/i18n';

import styles from './style.module.scss';

interface Props {
  icon?: string | null;
  policyDetailsProps: PolicyDetailsProps;
  isLoading: boolean;
  isSubmitButtonEnabled: boolean;
  priceInfo: PriceInfo;
  error?: string;
  isBeforePaypalRedirect: boolean;
  handleCheckout: () => void;
  setPaymentOption: (paymentOption: PaymentOption) => void;
  onStripePaymentMethodTypeChange: (type: string) => void;
  showPaymentSelector?: boolean;

  hasCheckoutDocuments?: boolean;
  documents?: CheckoutDocument[];
  isValidStartDate: boolean;
  changeStartDatePath?: string;
}

const View = ({
  policyDetailsProps,
  isLoading,
  isSubmitButtonEnabled,
  error,
  icon,
  priceInfo,
  handleCheckout,
  setPaymentOption,
  onStripePaymentMethodTypeChange,
  isBeforePaypalRedirect,
  showPaymentSelector = true,
  documents,
  hasCheckoutDocuments,
  isValidStartDate,
  changeStartDatePath,
}: Props) => {
  const { t } = useSafeTranslation();

  return (
    <div className="p-body">
      <div className="mt80 mb80 br8 bs-xs" data-cy="checkout-container">
        <div className="ta-center">
          {icon && (
            <img
              className={`${styles.icon} br8`}
              src={icon}
              alt="insurance icon"
            />
          )}
          <h1 className="p-h1 mt16">
            {t('paymentScreen.title', 'Review and purchase')}
          </h1>
        </div>
        <div className={`mt16 ${styles['checkout-info-container']}`}>
          <div className="ml16 mt16">
            <PolicyDetails {...policyDetailsProps} />
          </div>
          <div className="ml16 mt16">
            <PolicyHolder
              questionnaireId={
                policyDetailsProps.checkoutInfo.metaData?.questionnaireId
              }
            />
            <PaymentMethod
              priceInfo={priceInfo}
              setPaymentOption={setPaymentOption}
              onStripePaymentMethodTypeChange={onStripePaymentMethodTypeChange}
              showPaymentSelector={showPaymentSelector}
            />
          </div>
        </div>
        <div className={`mt32 w100 ${styles['button-container']}`}>
          <AnimateHeight duration={300} height={error ? 'auto' : 0}>
            <div className="p-notice p-notice--warning mb16 wmx8">{error}</div>
          </AnimateHeight>
          <AnimateHeight duration={300} height={!isValidStartDate ? 'auto' : 0}>
            <div className="p-notice p-notice--warning mb16 wmx8">
              {t(
                'components.checkout.startDateNotInFuture.errorMessage',
                'The coverage start date must be in the future.'
              )}
            </div>
            {!!changeStartDatePath && (
              <div className="d-flex ai-center jc-center mb32">
                <Link className="p-btn--primary wmn3" to={changeStartDatePath}>
                  {t(
                    'components.checkout.startDateNotInFuture.buttonCaption',
                    'Change start date'
                  )}
                </Link>
              </div>
            )}
          </AnimateHeight>
          <>
            {hasCheckoutDocuments ? (
              <>
                <div
                  className={classnames({
                    'd-flex fd-column gap32': hasCheckoutDocuments,
                    [styles.buttonDocsContainer]: hasCheckoutDocuments,
                  })}
                >
                  {hasCheckoutDocuments &&
                    documents &&
                    areDocumentsValid(documents) && (
                      <DocumentsReview documents={documents} />
                    )}
                  <button
                    className={classnames(
                      `p-btn--primary wmn3 ${styles.button}`,
                      {
                        'p-btn--loading': isLoading,
                      }
                    )}
                    disabled={!isSubmitButtonEnabled || !isValidStartDate}
                    onClick={handleCheckout}
                    data-cy="button-checkout"
                    type="button"
                  >
                    {isBeforePaypalRedirect
                      ? t(
                          'paymentScreen.button.paypal.caption',
                          'Continue with PayPal'
                        )
                      : t('paymentScreen.button.caption', 'Buy now')}
                  </button>
                </div>
                {!isBeforePaypalRedirect && (
                  <Markdown paragraphClassName="p-p--small ta-center wmx8 mt24">
                    {t(
                      'components.checkout.fineprint',
                      'The cover should begin on the chosen start date once you click "Buy now", but only after you have received the official confirmation.'
                    )}
                  </Markdown>
                )}
              </>
            ) : (
              <>
                <button
                  className={classnames(
                    `p-btn--primary wmn3 ${styles.button}`,
                    {
                      'p-btn--loading': isLoading,
                    }
                  )}
                  disabled={!isSubmitButtonEnabled || !isValidStartDate}
                  onClick={handleCheckout}
                  data-cy="button-checkout"
                  type="button"
                >
                  {isBeforePaypalRedirect
                    ? t(
                        'paymentScreen.button.paypal.caption',
                        'Continue with PayPal'
                      )
                    : t('paymentScreen.button.caption', 'Buy now')}
                </button>

                {!isBeforePaypalRedirect && (
                  <Markdown paragraphClassName="p-p--small ta-center wmx8 mt24">
                    {t(
                      'paymentScreen.finePrint',
                      'The cover will begin on the chosen start date if you click "Buy now", but only after you have received the official confirmation.'
                    )}
                  </Markdown>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default View;
