import Session from '@getpopsure/session';
import { fetchAccountInfo } from 'actions/user';
import { useGetEmailFromUrl } from 'hooks/useGetEmailFromUrl';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountInfo } from 'selectors/user';

import { validateAccount } from '../../actions/account';

interface UseEmailQuestionProps {
  onSubmitValue: (value: string) => void;
  setValidForSubmission: (valid: boolean) => void;
  showTerms?: boolean;
  value?: unknown;
}

interface UseEmailQuestionReturn {
  handleSubmit: () => void;
  isValid: boolean;
  onLogin: (loginEmail?: string) => void;
  handleModalClose: () => void;
  toggleTermsAccepted: () => void;
  termsAccepted: boolean;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  requiresLoginCode: boolean;
  email: string;
}

export const useEmailQuestion = ({
  onSubmitValue,
  setValidForSubmission,
  showTerms = true,
  value,
}: UseEmailQuestionProps): UseEmailQuestionReturn => {
  const account = useSelector(getAccountInfo);
  const dispatch = useDispatch();
  const { authenticate, clearEmailParam, emailParam } = useGetEmailFromUrl();

  const { isAuthenticated } = Session;

  const defaultEmail = (value as string) ?? account?.email ?? '';

  const [email, setEmail] = useState(defaultEmail);
  const [termsAccepted, setTermsAccepted] = useState(!showTerms);
  const [requiresLoginCode, setRequiresLoginCode] = useState(false);

  const isValid = email.length > 0 && termsAccepted;

  const handleModalClose = () => {
    setRequiresLoginCode(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const toggleTermsAccepted = () => {
    setTermsAccepted((accepted) => !accepted);
  };

  const handleValidateAccount = useCallback(
    (emailToValidate: string, onUserExists: (userExists: boolean) => void) => {
      if (emailToValidate === account?.email && isAuthenticated) {
        onSubmitValue(emailToValidate);
      } else {
        dispatch(validateAccount(emailToValidate, onUserExists));
      }
    },
    [account?.email, isAuthenticated, onSubmitValue, dispatch]
  );

  const handleSubmit = useCallback(() => {
    if (isValid) {
      handleValidateAccount(email, (userExists: boolean) => {
        if (userExists) {
          setRequiresLoginCode(true);
        } else {
          onSubmitValue(email);
        }
      });
    }
  }, [email, handleValidateAccount, isValid, onSubmitValue]);

  useEffect(() => {
    setValidForSubmission(email.length > 0 && termsAccepted);
  }, [email, termsAccepted, setValidForSubmission]);

  useEffect(() => {
    if (authenticate && emailParam) {
      setEmail(emailParam);
      handleSubmit();
      // clearEmailParam();
    }
  }, [authenticate, emailParam, clearEmailParam, handleSubmit]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchAccountInfo());
    }
  }, [dispatch, isAuthenticated]);

  const onLogin = (loginEmail?: string) => {
    setRequiresLoginCode(false);
    if (loginEmail) {
      onSubmitValue(loginEmail);
    }
  };

  return {
    handleSubmit,
    onLogin,
    handleModalClose,
    toggleTermsAccepted,
    termsAccepted,
    handleInputChange,
    requiresLoginCode,
    email,
    isValid,
  };
};
