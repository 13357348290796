import { UserWithBrokerMandate } from 'models/user';

export type PolicyHolderConfig = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  email?: string;
};

export const getPolicyHolderConfig = (
  userData?: UserWithBrokerMandate,
  questionnaire?: Record<string, unknown>
): PolicyHolderConfig => {
  return questionnaire
    ? getPolicyHolderDetailsFromQuestionnaire(userData, questionnaire)
    : getPolicyHolderDetailsFromUser(userData);
};

const getPolicyHolderDetailsFromUser = (userData?: UserWithBrokerMandate) => {
  return {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    dateOfBirth: userData?.dateOfBirth,
    email: userData?.email,
  };
};

const getPolicyHolderDetailsFromQuestionnaire = (
  userData?: UserWithBrokerMandate,
  questionnaire?: {
    answers?: {
      personalInfo?: {
        name: { firstName: string; lastName: string };
        dateOfBirth: string;
      };
    };
  }
) => {
  return {
    firstName: questionnaire?.answers?.personalInfo?.name.firstName,
    lastName: questionnaire?.answers?.personalInfo?.name.lastName,
    dateOfBirth: questionnaire?.answers?.personalInfo?.dateOfBirth,
    email: userData?.email,
  };
};
