import { useTranslation } from '@getpopsure/i18n-react';
import { Button, InformationBox, Input, InputProps } from '@popsure/dirty-swan';
import emailMisspelled, { top100 } from 'email-misspelled';
import { ChangeEvent, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import styles from './EmailCheckerInput.module.scss';

const EmailChecker = ({ onChange, value = '', ...rest }: InputProps) => {
  const { t } = useTranslation();
  const [emailSuggestion, setEmailSuggestion] = useState<string | null>(null);
  const [email, setEmail] = useState<string>(String(value));
  const emailChecker = emailMisspelled({ domains: top100 });

  const handleOnCorrectEmail = (correctedValue: string) => {
    setEmail(correctedValue);
    setEmailSuggestion(null);
    onChange?.({
      target: { value: correctedValue ?? '' },
    } as ChangeEvent<HTMLInputElement>);
  };

  useEffect(() => {
    if (!email) {
      setEmailSuggestion('');
      return;
    }

    const emailCheckResult = emailChecker(email);

    if (emailCheckResult?.length > 0) {
      setEmailSuggestion(emailCheckResult[0]?.corrected);
    } else {
      setEmailSuggestion('');
    }
  }, [email, emailChecker]);

  return (
    <>
      <Input
        type="email"
        autoComplete="email"
        {...rest}
        onChange={(e) => {
          setEmail(e.target.value);
          onChange?.(e);
        }}
        value={email ?? ''}
        ref={undefined}
      />
      <AnimateHeight duration={300} height={emailSuggestion ? 'auto' : 0}>
        <InformationBox variant="information" className="mt16 wmx5">
          <span data-cy="email-suggestion-message">
            {t('components.emailChecker.suggestion', 'Did you mean ')}{' '}
            <Button
              className={styles.button}
              onClick={() => handleOnCorrectEmail(emailSuggestion ?? '')}
              variant="textColor"
              type="button"
              data-testid="email-checker-button"
            >
              {emailSuggestion}
            </Button>
            ?
          </span>
        </InformationBox>
      </AnimateHeight>
    </>
  );
};

export default EmailChecker;
