import { TFunction } from '@getpopsure/i18n-react';
import { faq, website } from '@getpopsure/private-constants';
import {
  Insurer,
  INSURERS,
} from '@getpopsure/public-health-insurance-pricing-engine';
import { Card, illustrations } from '@popsure/dirty-swan';
import { dental } from 'models/insurances/assets/img';
import { Trans } from 'shared/i18n';
import { SignupQuestionnaireType } from 'SignupQuestionnaire';
import { coverageOneYearInTheFutureValidator } from 'SignupQuestionnaire/shared/customValidators';

import { submitDentalPostQuote } from '../actions';
import { NoticeTable } from '../components/NoticeTable/NoticeTable';
import { QuoteProcessing } from '../components/Processing';
import { Quote } from '../components/Quote';
import {
  Dental,
  genderMapping,
  GroupId,
  InsurerDetails,
  missingTeethMapping,
} from '../models';

export const DentalComponents = {
  NOTICE_TABLE: NoticeTable,
  QUOTE_PROCESSING: QuoteProcessing,
  QUOTE: Quote,
} as const;

export type DentalQuestionnaire = SignupQuestionnaireType<
  Dental,
  GroupId,
  typeof DentalComponents
>;

const germanPublicInsurers = INSURERS.reduce(
  (insurers: InsurerDetails[], insurer: Insurer) => {
    return [...insurers, { value: insurer, label: insurer }];
  },
  []
);

export const getTranslatedQuestionnaire = (
  t: TFunction
): DentalQuestionnaire => [
  {
    id: 'intro',
    required: true,
    type: 'INTRO',
    props: {
      title: t('dental.qnr.preQuote.intro.title', "Hey! Let's get you a quote"),
      subTitle: t(
        'dental.qnr.preQuote.intro.subTitle',
        'Just 2 questions, it takes less than a minute.'
      ),
    },
    screen: {
      continueButtonText: t(
        'dental.qnr.preQuote.intro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'preQuote',
  },
  {
    id: 'publicInsurer',
    type: 'DROPDOWN_SINGLE',
    required: true,
    props: {
      options: germanPublicInsurers,
      placeholder: t(
        'dental.qnr.preQuote.germanPublicInsurer.placeholder',
        "Provider's name"
      ),
    },
    screen: {
      question: t(
        'dental.qnr.preQuote.germanPublicInsurer.title',
        'Which German public health provider are you with?'
      ),
      additionalInfo: {
        title: t(
          'dental.qnr.preQuote.germanPublicInsurer.additionalInfo.title',
          'Why is public insurance required to sign up for dental insurance?'
        ),
        description: t(
          'dental.qnr.preQuote.germanPublicInsurer.additionalInfo.description',
          "Feather dental insurance serves as a supplement to your existing German public insurance. It’s only available for people insured through one of Germany's 100+ public health insurance providers (Krankenkassen), such as AOK, BARMER, DAK, or TK.\n\nTypically, public health insurance in Germany covers salaried employees and their dependents."
        ),
      },
      skipDetails: {
        title: t(
          'dental.qnr.preQuote.germanPublicInsurer.providerNotListed.message',
          "It's not listed"
        ),
        skipQuestion: { value: '' },
        path: '',
      },
    },
    groupId: 'preQuote',
  },
  {
    id: 'noPublicInsuranceBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: t(
        'dental.qnr.preQuote.noPublicInsuranceBlocker.title',
        'Unfortunately, we can’t sign you up'
      ),
      description: t(
        'dental.qnr.preQuote.noPublicInsuranceBlocker.description',
        'This dental insurance policy is available to those who are covered by a German public health insurance provider, such as AOK, BARMER, DAK, or TK.\n\nIf you have questions or would like more information on why this is a requirement, please feel free to contact us.'
      ),
      buttonProps: [
        { type: 'href', href: website.support, caption: 'Speak to us' },
      ],
    },
    screen: { layout: 'Standalone' },
    groupId: 'preQuote',
  },
  {
    id: 'dateOfBirth',
    type: 'DATE',
    required: true,
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 0 },
      },
    },
    screen: {
      question: t(
        'dental.qnr.preQuote.dateOfBirth.title',
        'When were you born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'day',
          value: 1,
        },
        msg: {
          type: 'Error',
          value: t(
            'dental.qnr.preQuote.dateOfBirth.errorMessage',
            'You can’t select a birth date in the future. Please make sure the date of birth is correct.'
          ),
        },
      },
    ],
    groupId: 'preQuote',
  },
  {
    id: 'quoteProcessing',
    required: true,
    type: 'QUOTE_PROCESSING',
    props: {},
    screen: {
      layout: 'Standalone',
    },
    groupId: 'preQuote',
  },
  {
    id: 'quote',
    type: 'QUOTE',
    required: true,
    props: {},
    screen: { layout: 'Standalone' },
    groupId: 'preQuote',
  },
  {
    id: 'signupIntro',
    required: true,
    type: 'CUSTOM_INTRO',
    props: {
      title: t(
        'dental.qnr.signup.signupIntro.title',
        "Great! Let's sign you up"
      ),
      subTitle: t(
        'dental.qnr.signup.signupIntro.subTitle',
        'It takes 1-2 minutes.'
      ),
      icon: {
        src: illustrations.shield,
        alt: 'shield',
        width: '85px',
      },
    },
    screen: {
      continueButtonText: t(
        'dental.qnr.signup.signupIntro.continueButtonText',
        'Get started'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'advancedNoticeScreen',
    type: 'CUSTOM_BOX',
    props: {
      content: (
        <Card
          title=""
          classNames={{ wrapper: 'wmx6', children: 'p-p--small wmx5' }}
        >
          <Trans i18nKey="dental.qnr.signup.advancedNoticeScreen.box.description">
            By selecting “Continue”, I agree that my health data may be
            processed, transferred and used by Feather and the risk carrier
            according to their health data protection policies. I agree to the
            terms about the release from confidentiality to both Feather and the
            risk carrier. My health data may be collected, stored and shared
            with third parties only where absolutely necessary by Feather and
            the risk carrier.
          </Trans>
        </Card>
      ),
    },
    screen: {
      question: t(
        'dental.qnr.signup.advancedNoticeScreen.title',
        'Before we continue…'
      ),
      description: t(
        'dental.qnr.signup.advancedNoticeScreen.description',
        'We have upcoming questions that may involve sensitive information. We want to inform you of the following:'
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'missingTeeth',
    type: 'RADIO',
    props: {
      mapValue: missingTeethMapping(t),
    },
    screen: {
      question: t(
        'dental.qnr.signup.missingTeeth.title',
        'Do you have any permanent teeth missing that have not been replaced?'
      ),
      additionalInfo: {
        title: t(
          'dental.qnr.signup.missingTeeth.additionalInfo.title',
          'What counts as a missing tooth?'
        ),
        description: t(
          'dental.qnr.signup.missingTeeth.additionalInfo.description',
          'Missing teeth are any adult teeth that have not been replaced through an implant or another tooth replacement procedure. Wisdom teeth are not considered as missing teeth if you had those removed. [Learn more]({{link}})',
          {
            link: faq.dentalRecommendedTreatment,
          }
        ),
      },
    },
    groupId: 'signup',
  },
  {
    id: 'missingTwoTeethNotice',
    type: 'NOTICE_TABLE',
    props: {
      description: t(
        'dental.qnr.signup.missingTwoTeethNotice.row.description',
        'I understand the reduced coverage and wish to continue.'
      ),
      rows: [
        [
          t(
            'dental.qnr.signup.missingTwoTeethNotice.row.one.left',
            'Years on policy'
          ),
          t(
            'dental.qnr.signup.missingTwoTeethNotice.row.one.right',
            '2 teeth missing'
          ),
        ],
        [
          t('dental.qnr.signup.missingTwoTeethNotice.row.two.left', '1 year'),
          t('dental.qnr.signup.missingTwoTeethNotice.row.two.right', '€250'),
        ],
        [
          t(
            'dental.qnr.signup.missingTwoTeethNotice.row.three.left',
            '2 years'
          ),
          t('dental.qnr.signup.missingTwoTeethNotice.row.three.right', '€250'),
        ],
        [
          t('dental.qnr.signup.missingTwoTeethNotice.row.four.left', '3 years'),
          t('dental.qnr.signup.missingTwoTeethNotice.row.four.right', '€250'),
        ],
        [
          t('dental.qnr.signup.missingTwoTeethNotice.row.five.left', '4 years'),
          t('dental.qnr.signup.missingTwoTeethNotice.row.five.right', '€5,250'),
        ],
        [
          t('dental.qnr.signup.missingTwoTeethNotice.row.six.left', '5+ years'),
          t(
            'dental.qnr.signup.missingTwoTeethNotice.row.six.right',
            'Unlimited'
          ),
        ],
      ],
    },
    screen: {
      question: t(
        'dental.qnr.signup.missingTwoTeethNotice.title',
        'Your coverage for tooth replacement procedures'
      ),
      additionalInfo: {
        title: t(
          'dental.qnr.signup.missingTwoTeethNotice.additionalInfo.title',
          'How does it work?'
        ),
        description: t(
          'dental.qnr.signup.missingTwoTeethNotice.additionalInfo.description',
          'You will have a total coverage limit of €6,000 over 4 years. The yearly limit for the first 3 years depends how many missing teeth you have at the time of sign up.\n\nAny unused yearly limit will be carried over to the next year.'
        ),
      },
    },
    groupId: 'signup',
  },
  {
    id: 'missingThreeTeethNotice',
    type: 'NOTICE_TABLE',
    props: {
      description: t(
        'dental.qnr.signup.missingThreeTeethNotice.row.description',
        'I understand the reduced coverage and wish to continue.'
      ),
      rows: [
        [
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.one.left',
            'Years on policy'
          ),
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.one.right',
            '3 teeth missing'
          ),
        ],
        [
          t('dental.qnr.signup.missingThreeTeethNotice.row.two.left', '1 year'),
          t('dental.qnr.signup.missingThreeTeethNotice.row.two.right', '€125'),
        ],
        [
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.three.left',
            '2 years'
          ),
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.three.right',
            '€125'
          ),
        ],
        [
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.four.left',
            '3 years'
          ),
          t('dental.qnr.signup.missingThreeTeethNotice.row.four.right', '€125'),
        ],
        [
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.five.left',
            '4 years'
          ),
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.five.right',
            '€5,625'
          ),
        ],
        [
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.six.left',
            '5+ years'
          ),
          t(
            'dental.qnr.signup.missingThreeTeethNotice.row.six.right',
            'Unlimited'
          ),
        ],
      ],
    },
    screen: {
      question: t(
        'dental.qnr.signup.missingTwoTeethNotice.title',
        'Your coverage for tooth replacement procedures'
      ),
      additionalInfo: {
        title: t(
          'dental.qnr.signup.missingTwoTeethNotice.additionalInfo.title',
          'How does it work?'
        ),
        description: t(
          'dental.qnr.signup.missingTwoTeethNotice.additionalInfo.description',
          'You will have a total coverage limit of €6,000 over 4 years. The yearly limit for the first 3 years depends how many missing teeth you have at the time of sign up.\n\nAny unused yearly limit will be carried over to the next year.'
        ),
      },
    },
    groupId: 'signup',
  },
  {
    id: 'missingTeethBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: t(
        'dental.qnr.signup.missingTeethBlocker.title',
        "Unfortunately, we can't sign you up"
      ),
      description: t(
        'dental.qnr.signup.missingTeethBlocker.description',
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nIf you'd like to understand why or have any questions you can speak to one of our insurance experts, and we'll see what we can do to help you."
      ),
      buttonProps: [
        { type: 'href', href: website.support, caption: 'Get in touch' },
      ],
    },
    screen: { layout: 'Standalone' },
    groupId: 'signup',
  },
  {
    id: 'hasTreatment',
    required: true,
    type: 'BOOLEAN',
    props: {},
    screen: {
      question: t(
        'dental.qnr.signup.hasTreatment.title',
        'Do you currently have any ongoing, planned, or recommended dental treatments?'
      ),
      additionalInfo: {
        title: t(
          'dental.qnr.signup.hasTreatment.additionalInformation.title',
          'What counts as ongoing treatments?'
        ),
        description: t(
          'dental.qnr.signup.hasTreatment.additionalInformation.description',
          'Any treatments or procedures recommended or prescribed by a dentist before your policy starts. Routine dental cleanings or treatments completed before purchasing the policy are not considered ongoing treatments.'
        ),
        moreInfoLink: faq.dentalRecommendedTreatment,
      },
    },
    groupId: 'signup',
  },
  {
    id: 'hasTreatmentBasic',
    type: 'CUSTOM_BOX',
    props: {},
    screen: {
      question: t(
        'dental.qnr.signup.hasTreatmentBasic.title',
        'Dental treatments planned, recommended, or already in progress before signing up will not be covered'
      ),
      buttonText: t(
        'dental.qnr.signup.hasTreatmentBasic.buttonText',
        'I understand'
      ),
      skipDetails: { path: website.support, title: 'Talk to us' },
    },
    groupId: 'signup',
  },
  {
    id: 'hasTreatmentBlocker',
    type: 'BLOCKER',
    props: {
      iconType: 'SHIELD',
      title: t(
        'dental.qnr.signup.hasTreatmentBlocker.title',
        "Unfortunately, we can't sign you up"
      ),
      description: t(
        'dental.qnr.signup.hasTreatmentBlocker.description',
        "We're working hard to make our policies cover all use cases, but still have certain limitations.\n\nIf you'd like to understand why or have any questions you can speak to one of our insurance experts, and we'll see what we can do to help you."
      ),
      buttonProps: [
        { type: 'href', href: website.support, caption: 'Get in touch' },
      ],
    },
    screen: { layout: 'Standalone' },
    groupId: 'signup',
  },
  {
    id: 'email',
    required: true,
    type: 'EMAIL',
    props: {
      verticalId: 'dentalV2',
    },
    screen: {
      layout: 'Standalone',
      question: t(
        'dental.qnr.signup.email.title',
        "What's your email address?"
      ),

      description: 'This policy will be accessible in your Feather account.',
    },
    groupId: 'signup',
  },
  {
    id: 'name',
    required: true,
    type: 'NAME',
    props: {},
    screen: {
      question: t('dental.qnr.signup.name.title', "What's your name?"),
    },
    groupId: 'signup',
  },
  {
    id: 'gender',
    required: true,
    type: 'RADIO',
    props: {
      mapValue: genderMapping(t),
    },
    screen: {
      question: t('dental.qnr.signup.gender.title', "What's your gender?"),
    },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianName',
    type: 'NAME',
    props: {},
    screen: {
      question: t(
        'dental.qnr.signup.legalGuardianName.title',
        "What's the name of your parent or legal guardian?"
      ),
      description: t(
        'dental.qnr.signup.legalGuardianName.description',
        "As you are under 18 years old, we need your parent / legal guardian's details to be able to sign you up."
      ),
    },
    groupId: 'signup',
  },
  {
    id: 'legalGuardianDateOfBirth',
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 100 },
        max: { op: 'subtract', type: 'years', value: 18 },
      },
    },
    screen: {
      question: t(
        'dental.qnr.signup.legalGuardianDateOfBirth.title',
        'When were they born?'
      ),
    },
    validations: [
      {
        op: 'dateDiff',
        variable: {
          type: 'year',
          value: 18,
        },
        msg: {
          type: 'Error',
          value: t(
            'dental.qnr.signup.legalGuardianDateOfBirth.errorMessage',
            'Legal guardian / parent should be over 18 years old.\n\nPlease make sure the birth date is correct.'
          ),
        },
      },
    ],
    groupId: 'signup',
  },
  {
    id: 'startDate',
    required: true,
    type: 'DATE',
    props: {
      yearRange: {
        min: { op: 'subtract', type: 'years', value: 0 },
        max: { op: 'add', type: 'years', value: 1 },
      },
    },
    screen: {
      question: t(
        'dental.qnr.signup.startDate.title',
        'When would you like your coverage to start?'
      ),
    },
    validations: [
      {
        op: 'DateIsInFuture',
        msg: {
          type: 'Error',
          value: t(
            'dental.qnr.startDate.validation.errorMessage',
            'Earliest start date is {{value}}. Please select another date.'
          ),
        },
      },
      coverageOneYearInTheFutureValidator(t),
    ],
    groupId: 'signup',
  },
  {
    id: 'reviewCheckout',
    required: true,
    type: 'REVIEW',
    props: {
      requestType: 'SUBMIT_QUESTIONNAIRE',
      reviewValues: [
        {
          title: t(
            'dental.qnr.signup.review.missingTeeth',
            'Permanent teeth missing?'
          ),
          value: {
            type: 'String',
            key: 'missingTeeth',
            valueMap: missingTeethMapping(t),
          },
          path: 'missingTeeth',
        },
        {
          title: t(
            'dental.qnr.signup.review.missingTwoTeethNotice',
            'Tooth replacement limits'
          ),
          value: {
            type: 'String',
            key: 'missingTwoTeethNotice',
            valueMap: {
              true: t(
                'dental.qnr.signup.review.missingTwoTeethNotice.mappedValue',
                'I understand'
              ),
            },
          },
          path: 'missingTwoTeethNotice',
        },
        {
          title: t(
            'dental.qnr.signup.review.missingThreeTeethNotice',
            'Tooth replacement limits'
          ),
          value: {
            type: 'String',
            key: 'missingThreeTeethNotice',
            valueMap: {
              true: t(
                'dental.qnr.signup.review.missingThreeTeethNotice.mappedValue',
                'I understand'
              ),
            },
          },
          path: 'missingThreeTeethNotice',
        },
        {
          title: t(
            'dental.qnr.signup.review.hasTreatment',
            'Ongoing, planned or recommended treatments?'
          ),
          value: {
            type: 'Boolean',
            key: 'hasTreatment',
          },
          path: 'hasTreatment',
        },
        {
          title: t(
            'dental.qnr.signup.review.hasTreatmentBasic',
            'No coverage for ongoing, planned or recommended treatments'
          ),
          value: {
            type: 'String',
            key: 'hasTreatmentBasic',
            valueMap: {
              true: t(
                'dental.qnr.signup.review.hasTreatmentBasic.mappedValue',
                'I understand'
              ),
            },
          },
          path: 'hasTreatmentBasic',
        },
        {
          title: t('dental.qnr.signup.review.email', 'Email'),
          value: { type: 'String', key: 'email' },
          path: 'email',
        },
        {
          title: t('dental.qnr.signup.review.name', 'Name'),
          value: {
            type: 'Strings',
            key: 'name',
            keys: ['firstName', 'lastName'],
          },
          path: 'name',
        },
        {
          title: t('dental.qnr.signup.review.dateOfBirth', 'Date of birth'),
          value: {
            type: 'Date',
            key: 'dateOfBirth',
            format: 'DD MMM YYYY',
          },
          path: 'dateOfBirth',
        },
        {
          title: t('dental.qnr.signup.review.gender', 'Gender'),
          value: { type: 'String', key: 'gender', valueMap: genderMapping(t) },
          path: 'gender',
        },
        {
          title: t(
            'dental.qnr.signup.review.legalGuardianName',
            "Parent / legal guardian's name"
          ),
          value: {
            type: 'Strings',
            key: 'legalGuardianName',
            keys: ['firstName', 'lastName'],
          },
          path: 'legalGuardianName',
        },
        {
          title: t(
            'dental.qnr.signup.review.legalGuardianDateOfBirth',
            "Parent / legal guardian's date of birth"
          ),

          value: {
            type: 'Date',
            key: 'legalGuardianDateOfBirth',
            format: 'DD MMM YYYY',
          },
          path: 'legalGuardianDateOfBirth',
        },
        {
          title: t('dental.qnr.signup.review.startDate', 'Coverage start date'),
          value: { type: 'Date', key: 'startDate', format: 'DD MMM YYYY' },
          path: 'startDate',
        },
      ],
      verticalId: 'dentalV2',
      insuranceType: 'DENTAL_V2',
      createPostQuote: () => submitDentalPostQuote(t),
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
  {
    id: 'checkoutInfo',
    required: true,
    type: 'GENERIC_CHECKOUT',
    props: {
      type: 'DENTAL_V2',
      policyInfoKind: 'DentalV2Policy',
      icon: dental,
      hasCheckoutDocuments: true,
      region: 'de',
      policyDetailsConfiguration: {
        policyDetails: [
          {
            title: t('dental.qnr.checkout.plan', 'Plan'),
            questionId: 'planId',
          },
          {
            title: t(
              'dental.qnr.checkout.provider',
              'Tooth replacement coverage'
            ),
            questionId: 'toothReplacementCoverage',
          },
          {
            title: t(
              'dental.qnr.checkout.policydetails.requestedStartDate',
              'Requested start date'
            ),
            questionId: 'startDate',
            additionalInfo: {
              title: t(
                'dental.qnr.checkout.policydetails.requestedStartDate.additionalInfo.title',
                'Requested start date'
              ),
              description: t(
                'dental.qnr.checkout.policydetails.requestedStartDate.additionalInfo.descriptionNoValue',
                'Your coverage should begin on the requested date, but only after you have received the official confirmation.'
              ),
            },
          },
        ],
        otherSections: [
          {
            title: t(
              'dental.qnr.checkout.legalGuardianTitle',
              'Parent / legal guardian'
            ),
            rows: [
              {
                title: t('dental.qnr.checkout.legalGuardianName', 'Name'),
                questionId: 'legalGuardianName',
              },
              {
                title: t(
                  'dental.qnr.checkout.legalGuardianDateOfBirth',
                  'Date of birth'
                ),
                questionId: 'legalGuardianDateOfBirth',
              },
            ],
          },
        ],
      },
    },
    screen: {
      layout: 'Standalone',
    },
    groupId: 'signup',
  },
];
